<template lang="pug">
.menu(v-if="restaurant", :class="{ [restaurant]: true }")
  .logo(:class="{ [restaurant]: true }", :style="{ 'background-image': `url('/img/restaurants/${ restaurant }/logo.png')` }")
  .logo-876(:class="{ hosted: true }",
            @click="restaurant = null",
            :style="{ 'background-image': `url('/img/876menu.png')` }")
  .page-border
  .closed-today(v-if="menu.info && !closedTodayHidden && menu.info.closed.includes(new Date().getDay())", @click="closedTodayHidden = true;")
    .text closed today
    .day {{ ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][new Date().getDay()] }}
  .info(v-if="menu.info") {{ menu.info.address }}&nbsp;&nbsp;&centerdot;&nbsp;&nbsp;
    a(:href="`tel:${ menu.info.phone[0].areaCode }-${ menu.info.phone[0].digits.replace(/\./g, '-') }`")
     span.area-code {{ menu.info.phone[0].areaCode }}.
     | {{ menu.info.phone[0].digits }}
  .heading(v-if="menu.info", :class="{ long: menu.info.title.replace(/^secrets\\s/i, '').length > 8 }")
    .hours {{ menu.info.hours.join(' ') }}
      .days {{ menu.info.days.join(' ') }}
    .title {{ menu.info.title.replace(/^secrets\s/i, '').replace(/\smobay$/i, '') }}
    .share(v-if="Object.keys(order).length > 2", @click="qrShown = true;")
      qr.code.small(:value="orderAs64",
                    :title="orderAs64",
                    render-as="svg",
                    foreground="#92683c")
      .text click to share order
  .items(v-if="menu.items")
    .item(v-for="({ n: title, bg, desc, price, options, beverage, fries, sauces, onePrice, side }) in menu.items",
          @click="selected = selected !== title && title || null",
          :class="{ selected: title === selected, 'has-count': count(title), [bg]: true }")
      .flip-container(:class="{ flipped: title === selected }")
        .card
          .front.bg(:style="{ 'background-image': bg && `url('/img/restaurants/${ restaurant }/mains/${ bg }.jpg')` || null }")
            .count(v-if="count(title)") {{ count(title) }}
          .back
            .count {{ count(title) }}
      //-.bg(:style="{ 'background-image': `url('/img/${ bg }.jpg')` }")
      .under
        .options(v-if="options")
          .option(v-for="price, kind in options",
                  @click.stop.prevent="addItem({ title, kind })"
                  :class="{ selected: inOrder(title, kind), 'big-count': inOrder(title, kind) > 9 }",
                  :count="inOrder(title, kind) || undefined") {{ kind }}
            .remove(v-if="inOrder(title, kind)",
                    :class="{ minus: inOrder(title, kind) > 1 }"
                    @click.stop.prevent="removeItem({ title, kind })")
              .text(v-if="inOrder(title, kind) > 1") &minus;
              .text(v-else) &times;
        .sauces(v-else-if="sauces")
          .sauce(v-for="kind in sauces",
                 @click.stop.prevent="addItem({ title, kind })",
                 :class="{ selected: inOrder(title, kind), 'big-count': inOrder(title, kind) > 9 }"
                 :count="inOrder(title, kind)") {{ kind }}
            .remove(v-if="inOrder(title, kind)",
                    @click.stop.prevent="removeItem({ title, kind })")
              .text(v-if="inOrder(title, kind) > 1") &minus;
              .text(v-else) &times;
        .add-remove(v-else)
          .add(@click.stop="addItem({ title })") &plus;
          .remove(@click.stop.prevent="removeItem({ title })",
                  :class="{ hide: !count(title) }") &minus;
      .details
        .title(:title="title") {{ title.substr(0, 20) }}
        .desc(v-for="line in desc") {{ (line || '').substr(0, 240) }}
        .sides
          .drink(v-if="beverage") drink
          .fries(v-if="fries") fries
          .side(v-if="side") side
        .prices
          .price(v-for="price, kind in prices(options, price, onePrice)", :class="{ selected: inOrder(title, kind, sauces) }")
            .count(v-if="inOrder(title, kind, sauces)") {{ inOrder(title, kind, sauces) }}
              .times &times;
            .text {{ kind || '' }}
            .amount {{ price }}
  .coming-soon(v-else, @click="restaurant = null") menu coming soon
  .beers(v-if="menu.beers")
    .beer(v-for="{ n: title, price } in menu.beers",
          @click.stop="addItem({ title })",
          :class="{ [title]: true }",
          :title="title.replace(/_/g, ' ')")
      .bg(:style="{ 'background-image': `url('/img/beers/${ title }.png')` }")
      .count(v-if="inOrder(title)") {{ inOrder(title) }}
      .price {{ price }}
      .remove(v-if="inOrder(title)", @click.stop.prevent="removeItem({ title })")
        .text(v-if="inOrder(title) > 1") &minus;
        .text(v-else) &times;
  .flasks(v-if="menu.flasks")
    .flask(v-for="{ n: title, price } in menu.flasks",
              @click.stop="addItem({ title })",
              :class="{ [title]: true }",
              :title="title.replace(/_/g, ' ')")
      .bg(:style="{ 'background-image': `url('/img/flasks/${ title }.png')` }")
      .count(v-if="inOrder(title)") {{ inOrder(title) }}
      .price {{ price }}
      .remove(v-if="inOrder(title)", @click.stop.prevent="removeItem({ title })")
        .text(v-if="inOrder(title) > 1") &minus;
        .text(v-else) &times;
  .beverages.bottom(v-if="menu.beverages")
    .beverage(v-for="{ n: title, price } in menu.beverages",
              @click.stop="addItem({ title })",
              :class="{ [title]: true }",
              :title="title.replace(/_/g, ' ')")
      .bg(:style="{ 'background-image': `url('/img/drinks/${ title }.jpg')` }")
      .count(v-if="inOrder(title)") {{ inOrder(title) }}
      .price {{ price }}
      .remove(v-if="inOrder(title)", @click.stop.prevent="removeItem({ title })")
        .text(v-if="inOrder(title) > 1") &minus;
        .text(v-else) &times;
  .sides.bottom(v-if="menu.sides")
    .side(v-for="{ n: title, price, noBg } in menu.sides",
              @click.stop="addItem({ title })",
              :class="{ [title]: true, 'no-bg': noBg }",
              :title="title.replace(/_/g, ' ').replace(/\-\-/g, '&')")
      .title {{ title.replace(/_/g, ' ').replace(/--/g, '&') }}
      .bg(v-if="!noBg", :style="{ 'background-image': `url('/img/restaurants/${ restaurant }/sides/${ title }.jpg')` }")
      .count(v-if="inOrder(title)") {{ inOrder(title) }}
      .price {{ price }}
      .remove(v-if="inOrder(title)", @click.stop.prevent="removeItem({ title })")
        .text(v-if="inOrder(title) > 1") &minus;
        .text(v-else) &times;
  .kids(v-if="menu.kids")
    .kid(v-for="{ n: title, price } in menu.kids",
              @click.stop="addItem({ title })",
              :class="{ [title]: true }",
              :title="title.replace(/_/g, ' ')")
      //-.title {{ title.replace(/_/g, ' ') }}
      .bg(:style="{ 'background-image': `url('/img/restaurants/${ restaurant }/kids/${ title }.jpg')` }")
      .count(v-if="inOrder(title)") {{ inOrder(title) }}
        .remove(v-if="inOrder(title)", @click.stop.prevent="removeItem({ title })")
          .text(v-if="inOrder(title) > 1") &minus;
          .text(v-else) &times;
    //- todo: make this dynamic
    .info
      .drink drink
      .fries fries
      .price 550
    .list
      .item(v-for="{ n: title } in menu.kids", :count="inOrder(title)")
        .count {{ inOrder(title) }}
          .times &nbsp;&times;
        .title {{ title.replace(/_/g, ' ') }}
        .remove(v-if="inOrder(title)", @click.stop.prevent="removeItem({ title })")
          .text(v-if="inOrder(title) > 1") &minus;
          .text(v-else) &times;

  .modal-qr(@click="qrShown = false", :class="{ shown: qrShown }")
    .close &times
    .heading(@touchstart.stop.prevent="share")
      span.show show
      | &nbsp; to server,&nbsp;
      a.whatsapp(:href="`https://wa.me/${ menu.info.whatsApp }?text=${ encoded64 }`",
        title="order via WhatsApp",
        @touchstart.stop="",
        target="_blank")
      | &nbsp; or &nbsp;
      span.share share
    sticker.modal(:href="orderAs64")
    //qr.code.big(:value="orderAs64", :title="orderAs64", render-as="svg", foreground="#92683c", :size="600")
    .location(v-if="order && order.meta &&  order.meta.stickerLocation")
      .label location
      .value {{ order.meta.stickerLocation }}
    .lp {{ order && order.meta &&  order.meta.lp }}
    .clear(@click="order = {}; selected = null;") clear order
.chooser(v-else)
  .banner
    .logo.two-wide(:style="{ 'background-image': `url('/img/876menu.png')` }")
    //.title 876menu.com
    .cta.two-wide click, eat, repeat
  .heading featured restaurants
  .restaurants
    .restaurant(v-for="item in restaurants",
                @click="restaurant = item",
                :class="{ [item]: true }")
      .title {{ item }}
      //.address {{ menus[item].address
      .bg(:style="{ 'background-image': `url('img/restaurants/${ item }/logo.png')` }")
</template>
<script>
//import HelloWorld from "./components/HelloWorld.vue";
import { mapGetters, mapActions } from 'vuex';
import { vuexComputed } from '@/helpers';
import QrcodeVue from 'qrcode.vue';
import sticker from '@/components/sticker';
export default {
  name: 'app',
  computed: {
    ...mapGetters(['menu', 'orderAs64', 'lp', 'restaurants', 'hosted']),
    ...vuexComputed('order', 'selected', 'qrShown', 'closedTodayHidden', 'restaurant'),
    encoded64() {
      return encodeURIComponent(this.orderAs64);
    }
  },
  methods: {
    ...mapActions(['addItem', 'removeItem']),
    prices(options, price, onePrice) {
      return onePrice && [price] || options || [price];
    },
    count(title) {
      const item = this.order[title];
      const values = Object.values(item || {});
      return (
        (values.length && values.reduce((total, value) => total + value, 0)) ||
        item ||
        0
      );
    },
    inOrder(title, kind, sauces) {
      const item = this.order[title];
      let r;
      if (sauces) {
        r = Object.values(item || [0]).reduce((total, value) => total + value, 0);
      } else if (kind) {
        r = item?.[kind];
      } else {
        r = item;
      }
      return r;
    },
    async share() {
      this.qrShown = true;
      //console.log('navigator has share', navigator.share);
      //alert(navigator.share || 'no share');
      if(navigator.share) {
        //alert('share?');
        try {
          const { lp, orderAs64 } = this;
          navigator
            .share({
              title: `my order - ${ lp }`,
              url: orderAs64
            })
            .then(() => {
              console.log('Article shared');
            })
            .catch(ex => {
              console.log('share failed', ex.message);
            });
          // success
          // console.log('Article shared');
          // alert('Article shared');
        } catch (ex) {
          // error message
          console.log('share failed', ex.message);
        }
      }
    }
  },
  components: { qr: QrcodeVue, sticker },
  mounted() {
    //this.lp = licensePlate();
  }
};
</script>

<style lang="scss">
@import './assets/scss/global.scss';
body {
  @media print {
    //max-width: 8.5in;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  @include s-mobile {
    margin: 0;
  }
  overflow-x: hidden;
  * {
    -webkit-tap-highlight-color: transparent;
  }
}
@include s-not-iphones {
  body {
  }
}
#app {
  max-width: 100vw;
  .page-border {
    display: none;
    pointer-events: none;
  }
  @media print {
    max-width: 8.5in;
    overflow: hidden;
    box-sizing: border-box;
    padding: 1.5rem;
    .page-border {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      box-sizing: border-box;
      border: 1rem solid #92683c;
      display: block;
      //@include s-not-iphones {
      //  display: block;
      //}
    }
  }
  overflow: hidden;
  font-family: 'Assistant', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
  @media only screen {
    padding-top: 3.5rem;
  }
  .menu {
    margin: 0 auto;
    width: 100vw;
    max-width: 100vw;
    //border: 0.75rem solid #92683c;
    position: relative;
    @media print {
      width: 8.5in;
      border-color: transparent;
      //border: 1rem solid #92683c;
    }
    //border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 1rem 1rem 1rem 0;
    @include s-iphones {
      padding-left: 1rem;
    }
    @include s-not-iphones {
      padding-left: 1rem;
      width: 8.5in;
      border: 1rem solid #92683c;
      //border: none;
    }
    @media print {
      padding: 1rem 1rem 1rem 0;
    }
    & > .logo-876 {
      position: fixed;
      bottom: 1.5rem;
      right: 0.5rem;
      width: 10rem;
      padding-top: 5%;
      background: no-repeat center right rgba(#fff, 0.7);
      background-size: contain;
      pointer-events: none;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border-right: 0.25rem solid rgba(#fff, 0.7);
      z-index: 1000;
      &.hosted {
        pointer-events: all;
        cursor: pointer;
      }
      @media print {
        bottom: 0;
        right: 0;
        background-color: white;
        border: 3px solid white;
        border-right-color: white;
        border-left-width: 0;
        border-right-width: 0;
        width: 8rem;
        padding-top: 3%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-position: 0.75rem center;
      }
    }
    .logo {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      @include s-tiny {
        top: -0.75rem;
        left: 1.75rem;
      }
      @include s-iphones {
        top: 4.75rem;
        right: -0.75rem;
        z-index: 1;
        background-color: white;
      }
      @include s-not-iphones {
        top: 0;
        left: 3.75rem;
        height: 8rem;
        width: 12rem;
      }
      @media print {
        top: 0;
        left: 12rem;
        height: 7rem;
        width: 10rem;
      }
      width: 8rem;
      height: 6rem;
      background: no-repeat center;
      background-size: contain;
      &.cliff-hanger {
        background-color: rgba(#475577, 0.7);
        transform: scale(0.7);
        transform-origin: top center;
        border-radius: 0.5rem;
        //background-size: 50%;
      }
      &.secrets-chill-out {
        transform: scale(0.75);
        transform-origin: top center;
        border-radius: 0.5rem;
        //background-size: 50%;
      }
      &.coastline-bliss {
        @media (screen and orientation:portrait) {
          transform: translate3d(-20%, -1.75rem, 0);
        }
      }
    }
    .whatsapp {
      cursor: pointer;
      user-select: none;
      display: inline-block;
      //position: absolute;
      //top: 0.5rem;
      //right: 0.5rem;
      width: 2rem;
      height: 2rem;
      box-sizing: border-box;
      transform: translate3d(0.125rem, 0.25rem, 0);
      //border: 2px solid #92683c;
      background: no-repeat center;
      background-size: contain;
      background-image: url("data:image/svg+xml;charset%3Dutf8,%3Csvg version%3D'1.1' xmlns%3D'http://www.w3.org/2000/svg' xmlns:xlink%3D'http://www.w3.org/1999/xlink' viewBox%3D'0 0 38 39' width%3D'38' height%3D'39' style%3D'enable-background:new 0 0 38 39;' xml:space%3D'preserve'%3E%3Cstyle type%3D'text/css'%3E.st0{fill:%23fff;}.icon_logo{fill:%2392683c;}%3C/style%3E%3Cpath class%3D'st0' d%3D'M10.7,32.8l0.6,0.3c2.5,1.5,5.3,2.2,8.1,2.2l0,0c8.8,0,16-7.2,16-16c0-4.2-1.7-8.3-4.7-11.3 c-3-3-7-4.7-11.3-4.7c-8.8,0-16,7.2-15.9,16.1c0,3,0.9,5.9,2.4,8.4l0.4,0.6l-1.6,5.9L10.7,32.8z'/%3E%3Cpath class%3D'icon_logo' d%3D'M32.4,6.4C29,2.9,24.3,1,19.5,1C9.3,1,1.1,9.3,1.2,19.4c0,3.2,0.9,6.3,2.4,9.1L1,38l9.7-2.5 c2.7,1.5,5.7,2.2,8.7,2.2l0,0c10.1,0,18.3-8.3,18.3-18.4C37.7,14.4,35.8,9.8,32.4,6.4z M19.5,34.6L19.5,34.6c-2.7,0-5.4-0.7-7.7-2.1 l-0.6-0.3l-5.8,1.5L6.9,28l-0.4-0.6c-4.4-7.1-2.3-16.5,4.9-20.9s16.5-2.3,20.9,4.9s2.3,16.5-4.9,20.9C25.1,33.8,22.3,34.6,19.5,34.6 z M28.3,23.5L27.2,23c0,0-1.6-0.7-2.6-1.2c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.5,0.1-0.7,0.2l0,0c0,0-0.1,0.1-1.5,1.7 c-0.1,0.2-0.3,0.3-0.5,0.3h-0.1c-0.1,0-0.3-0.1-0.4-0.2l-0.5-0.2l0,0c-1.1-0.5-2.1-1.1-2.9-1.9c-0.2-0.2-0.5-0.4-0.7-0.6 c-0.7-0.7-1.4-1.5-1.9-2.4l-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c0-0.2,0-0.4,0.1-0.5c0,0,0.4-0.5,0.7-0.8c0.2-0.2,0.3-0.5,0.5-0.7 c0.2-0.3,0.3-0.7,0.2-1c-0.1-0.5-1.3-3.2-1.6-3.8c-0.2-0.3-0.4-0.4-0.7-0.5h-0.3c-0.2,0-0.5,0-0.8,0c-0.2,0-0.4,0.1-0.6,0.1 l-0.1,0.1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.7,0.9-1.1,2-1.1,3.1l0,0c0,0.8,0.2,1.6,0.5,2.3l0.1,0.3c0.9,1.9,2.1,3.6,3.7,5.1l0.4,0.4c0.3,0.3,0.6,0.5,0.8,0.8c2.1,1.8,4.5,3.1,7.2,3.8c0.3,0.1,0.7,0.1,1,0.2l0,0c0.3,0,0.7,0,1,0 c0.5,0,1.1-0.2,1.5-0.4c0.3-0.2,0.5-0.2,0.7-0.4l0.2-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.4,0.3-0.9,0.4-1.4 c0-0.2,0-0.5,0-0.7C28.6,23.7,28.5,23.6,28.3,23.5z'/%3E%3C/svg%3E");
      //padding: 0.25rem 0.5rem;
    }
    & > .closed-today {
      cursor: pointer;
      position: fixed;
      top: 8.5rem;
      left: 0;
      right: 0;
      font-family: 'Assistant', Helvetica, Arial, sans-serif;
      box-sizing: border-box;
      border-top: 0.5rem solid rgba(red, 0.75);
      border-bottom: 0.5rem solid rgba(red, 0.75);
      padding: 1rem 0;
      background-color: rgba(white, 0.8);
      text-align: center;
      font-weight: 700;
      .text {
        color: white;
        font-size: 8rem;
        text-shadow: 1px 1px 1px red,
                     -1px 1px 1px red,
                     1px -1px 1px red,
                     -1px -1px 1px red;
      }
      .day {
        color: red;
        font-size: 2rem;
      }
      z-index: 99999;
    }
    & > .info {
      position: absolute;
      bottom: 0.5rem;
      text-align: center;
      left: 0;
      right: 0;
      font-family: 'Assistant', sans-serif;
      font-weight: 300;
      //color: rgba(#92683c, 0.9);
      color: #92683c;
      @media print {
        left: -1rem;
        right: 1rem;
      }
      a {
        color: #92683c;
        text-decoration: none;
        &:hover,
        &:visited,
        &:focus {
          color: #92683c;
          text-decoration: none;
          span.area-code {
            color: #bbb;
          }
        }
        span.area-code {
          color: #bbb;
        }
      }
    }
    & > .heading {
      position: relative;
      font-family: 'Playfair display', sans-serif;
      text-transform: uppercase;
      font-size: 4rem;
      &.long {
        font-size: 2rem;
      }
      @media print {
        font-size: 2rem;
      }
      @include s-tiny {
        font-size: 2rem;
      }
      font-weight: 700;
      color: #92683c;
      text-align: center;
      margin-bottom: 2rem;
      white-space: nowrap;
      @media only screen {
        .title {
          position: relative;
          left: 2rem;
        }
      }
      @include s-iphones {
        left: 0;
        border-bottom: 1rem solid #92683c;
        padding-bottom: 1rem;
        margin-top: -1rem;
      }
      & > .hours {
        position: absolute;
        left: 0.25rem;
        @media print {
          left: 0.75rem;
        }
        top: 50%;
        transform: translate3d(0, -50%, 0);
        font-weight: 400;
        font-size: 0.7rem;
      }
      & > .share {
        position: absolute;
        top: 1rem;
        @include s-mobile {
          top: -3rem;
          left: 1rem;
        }
        @media print {
          display: none;
        }
        @include s-iphones {
          top: -2.5rem;
        }
        left: 0;
        cursor: pointer;
        & > .text {
          position: absolute;
          top: 1rem;
          left: 4rem;
          font-size: 1rem;
          font-weight: 400;
        }
        & > .code {
          &.small {
            transform-origin: top left;
            transform: scale(0.5);
          }
        }
      }
    }
    .items {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 1.2rem;
      grid-row-gap: 2rem;
      margin-bottom: 4rem;
      page-break-after: always;
      margin-top: 4rem;
      @include s-not-iphones {
        margin-top: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      @media print {
        margin-top: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-right: 2rem;
        grid-row-gap: 1.2rem;
      }
      .item {
        cursor: pointer;
        position: relative;
        user-select: none;
        box-sizing: border-box;
        padding: 0.25rem 1rem 1rem 8rem;
        height: 9.5rem;
        @include s-iphones {
          //margin-bottom: 1.2rem;
        }
        &.has-count {
          &::before {
            content: '\00a0';
            position: absolute;
            top: 0;
            left: 0;
            bottom: -0.5rem;
            right: -0.5rem;
            box-sizing: border-box;
            border: 3px solid rgba(orange, 0.5);
            border-radius: 0.6rem;
            z-index: -1;
          }
          &.has-note {

          }
        }
        &.selected {
          & > .details {
            opacity: 0.5;
          }
          & > .under {
            opacity: 1;
            pointer-events: all;
            .options,
            .sauces {
              & > .selected {
                &[count] {
                  text-align: right;
                  &::before {
                    position: absolute;
                    top: 0.125rem;
                    left: 0.25rem;
                    content: attr(count);
                    font-weight: 700;
                  }
                  &.big-count {
                    padding-right: 0.125rem;
                    &::before {
                      left: 0.06125rem;
                    }
                  }
                  .remove {
                    text-align: center;
                  }
                }
              }
            }
          }
        }
        &:not(.selected) {
          & > .under {
            .options,
            .sauces {
              .remove {
                opacity: 0;
                pointer-events: none;
              }
            }
          }
        }
        .title {
          white-space: nowrap;
          font-family: 'Playfair display', sans-serif;
          text-transform: uppercase;
          font-size: 1.2rem;
          font-weight: 700;
          color: #92683c;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          margin-right: -2rem;
          text-align: left;
        }
        .prices {
          position: absolute;
          bottom: 0;
          right: 0;
          font-family: 'Playfair display', sans-serif;
          text-transform: uppercase;
          font-size: 1.2rem;
          font-weight: 700;
          color: #92683c;
          .price {
            display: flex;
            justify-content: flex-end;
            .count {
              display: grid;
              grid-template-columns: 1fr 0.5rem;
              grid-column-gap: 0.5rem;
              padding-right: 0.5rem;
              //font-family: 'Assistant', sans-serif;
              .times {
                padding-top: 0.125rem;
              }
            }
            .text {
              padding-top: 0.5rem;
              font-size: 0.7rem;
              margin-right: 0.25rem;
              color: #888;
            }
          }
        }
        .sides {
          position: absolute;
          bottom: 0;
          left: 8rem;
          width: 6rem;
          //background-color: #92683c;
          text-transform: uppercase;
          display: flex;
          font-size: 0.7rem;
          color: #777;
          & > * {
            box-sizing: border-box;
            //border: 1px solid #ccc;
            margin-right: 0.25rem;
            padding: 0.125rem 0.25rem;
            border-radius: 0.125rem;
            background-color: #92683c;
            font-weight: 600;
            color: white;
          }
          //& > * {
          //  position: absolute;
          //  font-size: 0.7rem;
          //}
          //.drink {
          //  left: 0;
          //}
          //.fries {
          //  right: 0;
          //  text-align: right;
          //}
        }
        .under {
          user-select: none;
          position: absolute;
          top: 0.4rem;
          left: 0.4rem;
          width: 7rem;
          height: 9rem;
          border-radius: 0.6rem;
          box-sizing: border-box;
          opacity: 0;
          transition: opacity 250ms ease-in-out;
          pointer-events: none;
          .options {
            display: flex;
            flex-direction: column;
            justify-content: center;
            //align-items: center;
            height: 100%;
            box-sizing: border-box;
            padding: 1rem 1rem 1rem 0.125rem;
            .option {
              cursor: pointer;
              position: relative;
              padding: 0.125rem 1rem;
              border: 1px solid #92683c;
              border-radius: 0.25rem;
              text-align: center;
              margin: 0.6rem 0;
              color: #92683c;
              white-space: nowrap;
              font-size: 0.8rem;
              &.selected {
                background-color: #92683c;
                color: white;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
              & > .remove {
                cursor: pointer;
                position: absolute;
                left: 100%;
                top: 50%;
                width: 2rem;
                height: 2.45rem;
                box-sizing: border-box;
                transform: translate3d(-0.25rem, -49%, 0) scale(0.58);
                //padding: 0.125rem 0.5rem;
                background-color: #92683c;
                border-radius: 0 0.5rem 0.5rem 0;
                font-weight: 700;
                font-size: 2rem;
                color: white;
                transition: opacity 400ms ease-in-out;
                &.minus {
                  transform: translate3d(-0.25rem, -51%, 0) scale(0.58);
                }
                .text {
                  position: relative;
                  top: -0.25rem;
                }

              }
            }
          }
          .sauces {
            display: flex;
            flex-direction: column;
            justify-content: center;
            //align-items: center;
            height: 100%;
            box-sizing: border-box;
            padding: 1rem;
            padding-left: 0.125rem;
            .sauce {
              cursor: pointer;
              position: relative;
              white-space: nowrap;
              box-sizing: border-box;
              padding: 0.125rem 0.25rem;
              border: 1px solid #92683c;
              border-radius: 0.25rem;
              text-align: center;
              margin: 0.125rem 0;
              color: #92683c;
              font-size: 0.8rem;
              background-color: white;
              &.selected {
                background-color: #92683c;
                color: white;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
              & > .remove {
                cursor: pointer;
                position: absolute;
                left: 100%;
                top: 50%;
                width: 2rem;
                height: 2.1rem;
                box-sizing: border-box;
                transform: translate3d(-0.125rem, -51%, 0) scale(0.7);
                //padding: 0.125rem 0.5rem;
                background-color: #92683c;
                border-radius: 0 0.5rem 0.5rem 0;
                font-weight: 700;
                font-size: 2rem;
                color: white;
                transition: opacity 400ms ease-in-out;
                .text {
                  position: relative;
                  top: -0.35rem;
                }

              }
            }
          }
          .add-remove {
            display: flex;
            flex-direction: column;
            justify-content: center;
            //align-items: center;
            height: 100%;
            box-sizing: border-box;
            padding: 1rem;
            .add, .remove {
              cursor: pointer;
              padding: 0.125rem 1rem;
              border: 1px solid #92683c;
              border-radius: 0.25rem;
              text-align: center;
              margin: 0.6rem 0;
              font-size: 2rem;
              font-weight: 700;
              &.hide {
                opacity: 0;
                pointer-events: none;
              }
            }
            .add {

            }
            .remove {

            }
          }
        }
        .flip-container {
          position: absolute;
          top: 0.5rem;
          left: 0.5rem;
          width: 7rem;
          height: 9rem;
          perspective: 50rem;
          border-radius: 0.5rem;
          //transform: translate3d(-8rem, 0, 0);
          z-index: 5;
          &.flipped {
            pointer-events: none;
            .card {
              transform: rotateY(180deg) translate3d(-90%, 0, 0);
            }
          }
          .card {
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: absolute;
            transform-style: preserve-3d;
            //transform-origin: 80% center;
            transform-origin: 70% center;
            transition: transform 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
            border-radius: 0.5rem;
            & > div {
              position: absolute;
              width: 100%;
              height: 100%;
              backface-visibility: hidden;
              border-radius: 0.5rem;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 6rem;
              font-weight: 700;
              font-family: 'Playfair display', sans-serif;
              & > .count {
                font-family: 'Assistant';
                backface-visibility: hidden;
                position: relative;
                top: -0.5rem;
                text-shadow: 1px 1px 1px black,
                             -1px 1px 1px black,
                             1px -1px 1px black,
                             -1px -1px 1px black;
              }
            }
            .back {
              transform: rotateY(180deg);
              background-color: #92683c;
              pointer-events: all;
              @media only print {
                visibility: hidden;
              }
            }
          }
        }
        & > .flip-container > .card > .bg {
          //position: absolute;
          //width: 7rem;
          //height: 9rem;
          background: #f8f8f8 center no-repeat;
          background-size: cover;
          border: 1px solid #dadada;
          //border-radius: 0.5rem;
        }
        &.ital_burger {
          & > .flip-container > .card > .bg {
            //background-color: #282828;
            //background-size: 110%;
          }
        }
        .desc {
          font-size: 0.9rem;
        }
      }
    }
    .beverages {
      display: grid;
      grid-template-columns: repeat(6, 3rem);
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;
      box-sizing: border-box;
      //border-bottom: 0.25rem solid #92683c;
      margin: 6rem 0 0;
      margin-top: 2rem;
      @media print {
        margin-top: -5rem;
        margin-left: 3rem;
        margin-right: 4rem;
      }
      & > :nth-child(6n + 1) {
        position: relative;
        margin-bottom: 5.5rem;
        &:last-child {
          margin-bottom: 0;
        }
        &::after {
          position: absolute;
          content: '\00a0';
          top: 100%;
          left: 1px;
          //left: -17.5rem;
          width: calc(100vw - 3rem);
          max-width: calc(8.5in - 4rem);
          //left: -17.5rem;
          height: 0.25rem;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2OYlGHzHwAE/AI2HUR5DwAAAABJRU5ErkJggg==);
          @media print {
            width: calc(100vw - 8rem);
          }
          @media only screen {
            background-color: #92683c;
          }
        }
      }
      &.top {
        display: none;
        & + .beers {
          display: none;
          & + .flasks {
            display: none;
          }
        }
      }
      @include s-iphones {
        &.top {
          //display: grid;
          & + .beers {
            //display: grid;
            & + .flasks {
              //display: grid;
            }
          }
        }
      }
      &.bottom {
        @media print {
          margin-bottom: 16rem;
        }
        & + .beers {
          & + .flasks {
            //margin-bottom: 16rem;
          }
        }
      }
      .beverage {
        cursor: pointer;
        user-select: none;
        height: 7rem;
        position: relative;
        & > .bg {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: top center no-repeat;
          background-size: cover;
        }
        &.pepsi {
          & > .bg {
            background-size: 125%;
          }
        }
        & > .count {
          position: absolute;
          top: -2rem;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          font-weight: 700;
          color: #92683c;
        }
        & > .price {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 1.75rem, 0);
          //font-weight: 400;
          color: #92683c;
          //color: rgba(#92683c, 0.75);
          font-size: 0.8rem;
          box-sizing: border-box;
          text-align: center;
        }
        & > .remove {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 0.25rem, 0);
          font-weight: 700;
          color: #92683c;
          background-color: white;
          box-sizing: border-box;
          //border: 1px solid #92683c;
          border: 1px solid white;
          border-top-width: 0;
          text-align: center;
          padding-top: 0.2rem;
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
    .beers {
      position: relative;
      display: grid;
      grid-template-columns: repeat(6, 3rem);
      @include s-not-iphones {
        grid-template-columns: repeat(12, 3rem);
      }
      @media (screen and orientation: landscape) {
        grid-template-columns: repeat(12, 3rem);
        & > :first-child {
          position: relative;
          margin-bottom: 5.5rem;
          &:last-child {
            margin-bottom: 0;
          }
          &::after {
            position: absolute;
            content: '\00a0';
            //content: 'here';
            //color: white;
            top: 100%;
            left: 1px;
            //left: -17.5rem;
            width: calc(100vw - 3rem);
            max-width: calc(8.5in - 4rem);
            //left: -17.5rem;
            height: 0.25rem;
            box-sizing: border-box;
            border-bottom: 0.25rem solid #92683c;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2OYlGHzHwAE/AI2HUR5DwAAAABJRU5ErkJggg==);
            @media only screen {
              background-color: #92683c;
            }
          }
        }
      }
      @media print {
        grid-template-columns: repeat(12, 3rem);
        margin-top: 3rem;
        margin-left: 3rem;
        margin-right: 4rem;
      }
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;
      @include s-mobile {
        grid-row-gap: 2.5rem;
        margin-bottom: 5.5rem;
      }
      box-sizing: border-box;
      //border-bottom: 0.25rem solid #92683c;
      margin: 2rem 0 0;
      margin-top: 6rem;
      @media print {
        &::after {
          content: '\00a0';
          position: absolute;
          top: 100%;
          left: 0.25rem;
          right: -1px;
          height: 0.25rem;
          transform: translate3d(0, 0, 0);
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2OYlGHzHwAE/AI2HUR5DwAAAABJRU5ErkJggg==);
        }
      }
      @include s-iphones {
        & > :nth-child(6n + 1) {
          position: relative;
          margin-bottom: 5.5rem;
          &:last-child {
            margin-bottom: 0;
          }
          &::after {
            position: absolute;
            content: '\00a0';
            top: 100%;
            left: 1px;
            //left: -17.5rem;
            width: calc(100vw - 3rem);
            max-width: calc(8.5in - 4rem);
            //left: -17.5rem;
            height: 0.25rem;
            box-sizing: border-box;
            border-bottom: 0.25rem solid #92683c;
            //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2OYlGHzHwAE/AI2HUR5DwAAAABJRU5ErkJggg==);
            @media only screen {
              background-color: #92683c;
            }
          }
        }
      }
      @include s-not-iphones {
        & > :first-child {
          position: relative;
          margin-bottom: 5.5rem;
          &:last-child {
            margin-bottom: 0;
          }
          &::after {
            position: absolute;
            content: '\00a0';
            //content: 'here';
            //color: white;
            top: 100%;
            left: 1px;
            //left: -17.5rem;
            width: calc(100vw - 3rem);
            max-width: calc(8.5in - 4rem);
            //left: -17.5rem;
            height: 0.25rem;
            box-sizing: border-box;
            border-bottom: 0.25rem solid #92683c;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2OYlGHzHwAE/AI2HUR5DwAAAABJRU5ErkJggg==);
            @media only screen {
              background-color: #92683c;
            }
          }
        }
      }
      .beer {
        cursor: pointer;
        user-select: none;
        height: 7rem;
        position: relative;
        & > .bg {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: top center no-repeat;
          background-size: cover;
        }
        &.red_stripe {
          & > .bg {
            background-size: 120%;
            background-position-y: 875%;
          }
        }
        &.heineken {
          & > .bg {
            background-size: 110%;
            background-position-y: -2%;
          }
        }
        &.guinness {
          & > .bg {
            background-size: 85%;
            background-position-y: -8%;
          }
        }
        &.dragon {
          & > .bg {
            background-size: 65%;
            background-position-y: -2000%;
          }
        }
        &.red_stripe_melon {
          & > .bg {
            background-size: 80%;
            background-position-y: 8%;
          }
        }
        &.red_stripe_sorrel {
          & > .bg {
            background-size: 150%;
            background-position-y: 18%;
          }
        }
        &.red_stripe_lemon {
          & > .bg {
            background-size: 150%;
            background-position-y: 18%;
          }
        }
        &.red_stripe_light {
          & > .bg {
            background-size: 80%;
            background-position-y: 8%;
          }
        }
        & > .count {
          position: absolute;
          top: -2rem;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          font-weight: 700;
          color: #92683c;
        }
        & > .price {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 1.75rem, 0);
          //font-weight: 400;
          color: #92683c;
          //color: rgba(#92683c, 0.75);
          font-size: 0.8rem;
          box-sizing: border-box;
          text-align: center;
        }
        & > .remove {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 0.25rem, 0);
          font-weight: 700;
          color: #92683c;
          background-color: white;
          box-sizing: border-box;
          //border: 1px solid #92683c;
          border: 1px solid white;
          border-top-width: 0;
          text-align: center;
          padding-top: 0.2rem;
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
    .flasks {
      display: grid;
      grid-template-columns: repeat(5, 3.6rem);
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;
      box-sizing: border-box;
      //border-bottom: 0.25rem solid #92683c;
      margin: 2rem 0 4.5rem;
      @media (screen and orientation: portrait) {
        & > :nth-child(6),
        & > :nth-child(7) {
          display: none;
        }
      }
      //@include s-not-iphones {
        //grid-template-columns: repeat(7, 3.6rem);
      //}
      @media (screen and orientation: landscape) {
        grid-template-columns: repeat(7, 3.6rem);
      }
      @media print {
        margin-top: 4rem;
        margin-left: 3rem;
        margin-right: 4rem;
        grid-template-columns: repeat(7, 3.6rem);
        & > :nth-child(6),
        & > :nth-child(7) {
          display: block;
        }
      }
      & > :nth-child(7n + 1) {
        position: relative;
        margin-bottom: 5.5rem;
        &:last-child {
          margin-bottom: 0;
        }
        &::after {
          position: absolute;
          content: '\00a0';
          top: 100%;
          left: 1px;
          //left: -17.5rem;
          width: calc(100vw - 3rem);
          max-width: calc(8.5in - 4rem);
          //left: -17.5rem;
          height: 0.25rem;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2OYlGHzHwAE/AI2HUR5DwAAAABJRU5ErkJggg==);
          @media print {
            width: calc(100vw - 8rem);
          }
          @media only screen {
            background-color: #92683c;
          }
        }
      }
      .flask {
        cursor: pointer;
        user-select: none;
        height: 7rem;
        position: relative;
        & > .bg {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: top center no-repeat;
          background-size: cover;
        }
        &.white_rum {
          & > .bg {
            background-position-y: -121%;
          }
        }
        &.smirnoff {
          & > .bg {
            background-size: 275%;
            background-position-y: 27%;
          }
        }
        &.coco_mania {
          & > .bg {
            background-size: 89%;
            background-position-y: 254%;
          }
        }
        & > .count {
          position: absolute;
          top: -2rem;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          font-weight: 700;
          color: #92683c;
        }
        & > .price {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 1.75rem, 0);
          //font-weight: 400;
          color: #92683c;
          //color: rgba(#92683c, 0.75);
          font-size: 0.8rem;
          box-sizing: border-box;
          text-align: center;
        }
        & > .remove {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 0.25rem, 0);
          font-weight: 700;
          color: #92683c;
          background-color: white;
          box-sizing: border-box;
          //border: 1px solid #92683c;
          border: 1px solid white;
          border-top-width: 0;
          text-align: center;
          padding-top: 0.2rem;
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
    & > .sides {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include s-not-iphones {
        grid-template-columns: repeat(7, 1fr);
      }
      @media (screen and orientation: landscape) {
        grid-template-columns: repeat(7, 1fr);
      }
      &.top {
        //display: none;
      }
      @media print {
        grid-template-columns: repeat(7, 1fr);
        padding-right: 2rem;
        &.top {
          //display: grid;
          page-break-after: always;
          margin-top: 6rem;
        }
        &.bottom {
          //display: none;
        }
      }
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;
      box-sizing: border-box;
      //border-bottom: 0.25rem solid #92683c;
      margin: 4rem 0 4.5rem;
      margin-bottom: 18rem;
      @media print {
        margin-top: -16rem;
        //margin-bottom: -2rem;
      }
      & > :nth-child(4n + 1) {
        position: relative;
        margin-bottom: 3.5rem;
        &:last-child {
          margin-bottom: 0;
        }
        &::after {
          position: absolute;
          content: '\00a0';
          top: 100%;
          left: 1px;
          //left: -17.5rem;
          width: calc(100vw - 3rem);
          max-width: calc(8.5in - 4rem);
          //left: -17.5rem;
          //height: 0.25rem;
          //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2OYlGHzHwAE/AI2HUR5DwAAAABJRU5ErkJggg==);
          @media only screen {
            //background-color: #92683c;
          }
        }
      }
      .side {
        cursor: pointer;
        user-select: none;
        height: 7rem;
        position: relative;
        border-radius: 0.5rem;
        &.no-bg {
          height: 2rem;
          & > .count {
            font-weight: 700;
            font-size: 2rem;
            color: #92683c;
            text-shadow: none;
          }
          & > .remove {
            top: -0.5rem;
            right: -0.5rem;
            width: 1.75rem;
            height: 1.75rem;
            transform: translate3d(-50%, 0.25rem, 0);
            font-weight: 700;
            font-size: 1rem;
            text-shadow: none;
            color: #92683c;
          }
        }
        & > .title {
          position: absolute;
          top: 100%;
          left: 50%;
          color: #92683c;
          font-weight: 600;
          transform: translate3d(-50%, 0.25rem, 0);
          white-space: nowrap;
          text-transform: uppercase;
          font-size: 0.75rem;
        }
        & > .bg {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #f8f8f8 top center no-repeat;
          background-size: cover;
          border-radius: 0.5rem;
          border: 1px solid #dadada;
        }
        &.fries {
          & > .bg {
            background-position-y: 40%;
            background-size: 260%;
          }
        }
        &.roti {
          & > .bg {
            background-position-y: 62%;
            background-size: 207%;
          }
        }
        & > .count {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          font-weight: 700;
          font-size: 4rem;
          color: white;
          font-family: 'Assistant', sans-serif;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          text-shadow: 1px 1px 1px black,
                       -1px 1px 1px black,
                       1px -1px 1px black,
                       -1px -1px 1px black;
        }
        & > .price {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 1.75rem, 0);
          //font-weight: 400;
          color: #92683c;
          //color: rgba(#92683c, 0.75);
          font-size: 0.8rem;
          box-sizing: border-box;
          text-align: center;
        }
        & > .remove {
          position: absolute;
          top: -1rem;
          right: -1rem;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 0.25rem, 0);
          font-weight: 700;
          font-size: 2rem;
          text-shadow: 1px 1px 1px black,
                       -1px 1px 1px black,
                       1px -1px 1px black,
                       -1px -1px 1px black;

          //color: #92683c;
          color: white;
          //background-color: white;
          box-sizing: border-box;
          //border: 1px solid #92683c;
          //border: 1px solid white;
          border-top-width: 0;
          text-align: center;
          padding-top: 0.2rem;
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
    & > .kids {
      position: relative;
      top: -6rem;
      display: grid;
      width: 50vw;
      height: 50vw;
      max-width: 4.25in;
      max-height: 4.25in;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      @include s-not-iphones {
        //grid-template-columns: repeat(6, 1fr);
      }
      //grid-column-gap: 0.5rem;
      //grid-row-gap: 0.5rem;
      box-sizing: border-box;
      //border-bottom: 0.25rem solid #92683c;
      margin: -11rem auto 5rem;
      //margin-left: 0.5rem;
      margin-left: auto;
      margin-right: auto;
      @media print {
        margin-top: -16rem;
        margin-bottom: -2rem;
        margin-left: auto;
        margin-right: auto;
        left: -1rem;
      }
      &::before {
        content: 'kids';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 2;
        font-family: 'Assistant', sans-serif;
        font-size: 10vw;
        @include s-not-iphones {
          font-size: 4.5rem;
        }
        //font-size: 150%;
        color: #92683c;
        font-weight: 700;
        text-transform: uppercase;
      }
      &::after {
        content: '\00a0';
        position: absolute;
        top: 27.5%;
        left: 27.5%;
        bottom: 27.5%;
        right: 27.5%;
        background-color: white;
        border-radius: 25%;
      }
      & > .info {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate3d(-50%, 0.75rem, 0);
        z-index: 2;
        display: flex;
        font-size: 0.7rem;
        color: #92683c;
        .drink,
        .fries,
        .side {
          box-sizing: border-box;
          margin-right: 0.25rem;
          padding: 0.125rem 0.25rem;
          //padding-bottom: 0;
          border-radius: 0.125rem;
          background-color: #92683c;
          font-size: 0.7rem;
          font-weight: 600;
          color: white;
          text-transform: uppercase;
        }
        .price {
          font-family: "Playfair display", sans-serif;
          text-transform: uppercase;
          //font-size: 1.2rem;
          font-weight: 700;
          color: #92683c;
          margin-left: 1rem;
          transform: scale(1.6) translate3d(0, -1px, 0);
        }
      }
      & > .list {
        position: absolute;
        top: 25%;
        left: 100%;
        margin-left: 0.25rem;
        @include s-tiny {
          margin-left: 0.125rem;
        }
        @media print {
          margin-left: 1rem;
        }
        & > .item {
          position: relative;
          display: none;
          padding-top: 0.5rem;
          margin-right: 0.25rem;
          font-family: "Playfair display", sans-serif;
          text-transform: uppercase;
          font-size: 1.1rem;
          font-weight: 700;
          color: #92683c;
          &[count] {
            display: flex;
          }
          @media print {
            display: flex;
          }
          & > .title {
            white-space: nowrap;
            font-weight: 400;
            font-size: 0.7rem;
            position: relative;
            top: 0.25rem;
            @include s-tiny {
              font-size: 0.6rem;
            }
          }
          & > .remove {
            cursor: pointer;
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translate3d(1rem, -50%, 0);
            @include s-tiny {
              transform: translate3d(0.25rem, -50%, 0);
            }
            font-size: 1.1rem;
            font-weight: 700;
            @media print {
              display: none;
            }
          }
          & > .count {
            position: relative;
            top: -0.125rem;
            white-space: nowrap;
            margin-right: 0.25rem;
            min-width: 2rem;
            text-align: right;
            @media print {
              display: none;
            }
            & > .times {
              position: relative;
              top: 0.125rem;
              display: inline-block;
            }
          }
        }
      }
      .kid {
        cursor: pointer;
        user-select: none;
        //padding-top: 100%;
        //height: 10rem;
        position: relative;
        border-radius: 0.5rem;
        &:first-child {
          & > .bg {
            border-top-left-radius: 50%;
            border-right-width: 0;
            border-bottom-width: 0;
          }
          & > .count {
            transform: translate3d(-90%, -90%, 0);
          }
        }
        &:nth-child(2) {
          & > .bg {
            border-top-right-radius: 50%;
            border-left-width: 0;
            border-bottom-width: 0;
          }
          & > .count {
            transform: translate3d(-10%, -90%, 0);
          }
        }
        &:nth-child(3) {
          & > .bg {
            border-bottom-left-radius: 50%;
            border-right-width: 0;
            border-top-width: 0;
          }
          & > .count {
            transform: translate3d(-90%, -10%, 0);
          }
        }
        &:nth-child(4) {
          & > .bg {
            border-bottom-right-radius: 50%;
            border-left-width: 0;
            border-top-width: 0;
          }
          & > .count {
            transform: translate3d(-10%, -10%, 0);
          }
        }
        & > .title {
          position: absolute;
          top: 100%;
          left: 50%;
          color: #92683c;
          font-weight: 600;
          transform: translate3d(-50%, 0.25rem, 0);
          white-space: nowrap;
          text-transform: uppercase;
          font-size: 0.8rem;
        }
        & > .bg {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: top center no-repeat;
          background-size: cover;
          border-radius: 0;
          border: 1px solid #dadada;
        }
        & > .count {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          font-weight: 700;
          font-size: 7vw;
          color: white;
          font-family: 'Assistant', sans-serif;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          text-shadow: 1px 1px 1px black,
                       -1px 1px 1px black,
                       1px -1px 1px black,
                       -1px -1px 1px black;
          & > .remove {
            position: absolute;
            top: -1.25rem;
            right: -1.25rem;
            width: 1.5rem;
            height: 1.5rem;
            transform: translate3d(0, 0, 0);
            font-weight: 700;
            font-size: 1.75rem;
            text-shadow: 1px 1px 1px black,
            -1px 1px 1px black,
            1px -1px 1px black,
            -1px -1px 1px black;

            //color: #92683c;
            color: white;
            //background-color: white;
            box-sizing: border-box;
            //border: 1px solid #92683c;
            //border: 1px solid white;
            border-top-width: 0;
            text-align: center;
            padding-top: 0.2rem;
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
          }
        }
        & > .price {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1.75rem;
          height: 1.75rem;
          transform: translate3d(-50%, 1.75rem, 0);
          //font-weight: 400;
          color: #92683c;
          //color: rgba(#92683c, 0.75);
          font-size: 0.8rem;
          box-sizing: border-box;
          text-align: center;
        }
      }
    }
  }
  .modal-qr {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate3d(-100%, 0, 0);
    transition: transform 250ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: white;
    box-sizing: border-box;
    padding: 0.5rem;
    z-index: 15000;
    &.shown {
      transform: translate3d(0, 0, 0);
      pointer-events: all;
    }
    & > .code {
      position: relative;
      top: -8rem;
    }
    & > .modal {
      //position: absolute;
      //top: 0;
      //left: 0;
      transform: rotate(90deg) translate3d(-4rem, 0, 0) scale(1.6);
      transform-origin: center;
    }
    & > .heading {
      pointer-events: all;
      position: absolute;
      bottom: 4rem;
      left: 0;
      right: 0;
      user-select: none;
      text-align: center;
      font-size: 1.4rem;
      color: black;
      box-sizing: border-box;
      padding: 1rem;
      //outline: 1px solid #ccc;
      z-index: 1;
      .show {
        font-weight: 700;
      }
      .share {
        //cursor: pointer;
        pointer-events: none;
        position: relative;
        color: #92683c;
        box-sizing: border-box;
        border: 1px solid #92683c;
        padding: 0.25rem 1.5rem;
        padding-right: 0.5rem;
        border-radius: 0.25rem;
        background: no-repeat left center;
        background-image: url("data:image/svg+xml;charset%3Dutf8,%3Csvg xmlns%3D'http://www.w3.org/2000/svg' viewBox%3D'0 0 50 50' enable-background%3D'new 0 0 50 50'%3E%3Cg style%3D'fill: %238d6e4f;'%3E%3Cpath d%3D'M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z'/%3E%3Cpath d%3D'M24 7h2v21h-2z'/%3E%3Cpath d%3D'M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z'/%3E%3C/g%3E%3C/svg%3E");
        background-size: 1.5rem;
        &.grey {
          background-image: url("data:image/svg+xml;charset%3Dutf8,%3Csvg xmlns%3D'http://www.w3.org/2000/svg' viewBox%3D'0 0 50 50' enable-background%3D'new 0 0 50 50'%3E%3Cg style%3D'fill: %23dadada;'%3E%3Cpath d%3D'M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z'/%3E%3Cpath d%3D'M24 7h2v21h-2z'/%3E%3Cpath d%3D'M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z'/%3E%3C/g%3E%3C/svg%3E");
        }
        &:hover {
          border-color: black;
        }
        &::after {
          cursor: pointer;
          pointer-events: all;
          content: '\00a0';
          position: absolute;
          top: -0.75rem;
          left: -0.75rem;
          bottom: -0.75rem;
          right: -0.75rem;
          //background-color: #92683c;
          opacity: 0.5;
          border-radius: 0.25rem;
        }
        display: inline-block;
      }
    }
    .close {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
      color: #888;
      box-sizing: border-box;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      //border: 1px solid #ccc;
    }
    .clear {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
      color: #ccc;
      box-sizing: border-box;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid #ccc;
    }
    .location {
      position: absolute;
      bottom: 2rem;
      left: 0.5rem;
      //cursor: pointer;
      color: #888;
      box-sizing: border-box;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      display: flex;
      justify-content: flex-start;
      .value {
        margin-left: 0.25rem;
        color: black;
      }

    }
    .lp {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      cursor: pointer;
      color: #888;
      box-sizing: border-box;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      //border: 1px solid #888;
    }
  }
  .coming-soon {
    font-size: 5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .chooser {
    & > .banner {
      display: grid;
      grid-template-columns: 4rem 1fr;
      .logo {
        background: white center no-repeat;
        background-size: contain;
        padding-top: 10%;
      }
      .title {
        font-size: 4rem;
        text-align: center;
        transform: translate3d(-4rem, 0, 0);
      }
      .cta {
        text-align: center;
        color: #555;
      }
      .two-wide {
        grid-column: 1 / 3;
      }
    }
    & > .heading {
      text-align: center;
      font-size: 2rem;
      text-transform: uppercase;
    }
    .restaurants {
      margin: 0 auto;
      max-width: 6in;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .restaurant {
        cursor: pointer;
        position: relative;
        padding-top: 100%;
        //  background-color: #eee;
        box-sizing: border-box;
        border: 1px solid #eee;
        //border: 1px solid #333;
        &.margaritaville-mobay {
          opacity: 0;
          grid-column: 1 / 4;
          grid-row: 1 / 3;
        }
        &.pit-stop {
          //grid-column: 1 / 4;
          //grid-row: 1 / 3;
          //grid-column: 3 / 5;
          //grid-row: 3 / 5;
          grid-column: 1 / 3;
          grid-row: 3 / 5;
        }
        &.covers {
          grid-column: 4 / 5;
          grid-row: 2 / 3;
        }
        &.roots21 {
          //opacity: 0;
          //pointer-events: none;
          grid-column: 1 / 4;
          grid-row: 1 / 3;
          //grid-column: 3 / 5;
          //grid-row: 3 / 5;
        }
        &.coastline-bliss {
          opacity: 0;
          pointer-events: none;
          grid-column: 3 / 5;
          grid-row: 3 / 5;
        }
        &.cliff-hanger {
          //grid-column: 1 / 3;
          //grid-row: 3 / 5;
          grid-column: 3 / 5;
          grid-row: 3 / 5;
          .bg {
            //background-color: rgba(#11c, 0.6);
            background-color: rgba(#475577, 0.7);
          }
        }
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: no-repeat center;
          background-size: contain;
        }
        .title {
          position: absolute;
          bottom: 0.5rem;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          font-size: 2rem;
          font-weight: 700;
          color: black;
          white-space: nowrap;
          z-index: 2;
          visibility: hidden;
        }
      }
    }
  }
}
</style>
