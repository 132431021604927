import { createStore } from 'vuex';
import { vuexSimple, licensePlate } from '../helpers';
const props = {
  selected: null,
  order: {},
  qrShown: false,
  lp: licensePlate(),
  fromSticker: null,
  stickerLocation: null,
  closedTodayHidden: false,
  restaurant: null,
  hosted: /localhost|876menu|qrl/.test(location.href)
};
const noBg = true;
export default createStore(
  vuexSimple(
    {
      mutations: {
        addItem({ order }, { title, kind }) {
          console.log('addItem', title, kind || 'no kind');
          if (kind) {
            order[title] = order[title] || {};
            order[title][kind] = order[title][kind] || 0;
            ++order[title][kind];
          } else {
            order[title] = order[title] || 0;
            ++order[title];
          }
        },
        removeItem({ order }, { title, kind }) {
          if (kind) {
            if (!--order[title][kind]) {
              delete order[title][kind];
              if (!Object.keys(order[title]).length) {
                delete order[title];
              }
            }
          } else {
            if (!--order[title]) {
              delete order[title];
            }
          }
        }
      },
      actions: {
        async addItem({ commit, dispatch }, value) {
          await commit('addItem', value);
          dispatch('syncUrl');
        },
        async removeItem({ commit, dispatch }, value) {
          await commit('removeItem', value);
          dispatch('syncUrl');
        },
        syncUrl({ getters: { orderAs64 } }) {
          // console.log('order', JSON.stringify(order));
          history.replaceState(null, 'pit stop', orderAs64);
        }
      },
      getters: {
        restaurants() {
          return ['margaritaville-mobay', 'secrets-chill-out', 'covers', 'roots21', 'pit-stop', 'cliff-hanger', 'coastline-bliss'];
        },
        order({ order }, { lp, restaurant, fromSticker, stickerLocation }) {
          return { ...order, meta: { lp, fromSticker, stickerLocation }, restaurant };
        },
        menu(state, { restaurant, menus }) {
          return restaurant && menus[restaurant] || {};
        },
        menus() {
          return {
            'pit-stop': {
              items: [
                // {
                //   n: 'high octane',
                //   bg: 'high_octane',
                //   desc: ['3 Cheeseburger sliders topped with lettuce, tomato, onion, pickle, house special sauce'],
                //   fries: true,
                //   beverage: true,
                //   price: 1200
                // },
                {
                  n: 'rush',
                  bg: 'rush',
                  desc: ['One chicken, or shrimp taco with lettuce tomato and cheese'],
                  options: { chicken: 600, shrimp: 800 }
                },
                {
                  n: 'ital burger',
                  bg: 'ital_burger',
                  desc: ['Release your inner Rasta!', 'Veggie patty topped with lettuce, tomato, onion, and house special sauce', 'lik dem jaw'],
                  fries: true,
                  price: 1200
                },
                {
                  n: 'full throttle',
                  bg: 'full_throttle',
                  desc: [
                    'Quesadilla stuffed with cheese, scallion, egg, corn',
                    'chicken, or shrimp'
                  ],
                  // sauces: ['beef', 'chicken', 'shrimp'],
                  options: { chicken: 1100, shrimp: 1200 }//,
                  //onePrice: true,
                  // price: 1100
                },
                // {
                {
                  n: 'quick fix',
                  bg: 'quick_fix',
                  desc: [
                    'Corn, red beans, rice',
                    'it\'s what you want'
                  ],
                  // sauces: ['beef', 'chicken', 'shrimp'],
                  options: { chicken: 1000, shrimp: 1200, salmon: 1600 }//,
                  //onePrice: true,
                  // price: 1100
                },
                // {
                //   n: 'the electrifier',
                //   bg: 'the_electrifier',
                //   desc: [
                //     'Fry dumplings with side of Ackee and Callaloo'
                //   ],
                //   price: 500
                // },
                // {
                //   n: 'irie bleu',
                //   bg: 'irie_bleu',
                //   desc: [
                //     'Cordon bleu, with 876 kick'
                //   ],
                //   fries: true,
                //   sauces: ['fries', 'mash potatoes'],
                //   price: 1400
                // },
                // {
                //   n: 'adrenaline special',
                //   bg: 'adrenaline_special',
                //   desc: ['House special blend veggie ital stew', 'Roti skin'],
                //   price: 900
                // },
                {
                  n: 'buss belly',
                  bg: 'buss_belly',
                  desc: [
                    'Beef patty or crispy fried chicken sandwich, topped with lettuce, tomato, cheese, and house special sauce'
                  ],
                  fries: true,
                  options: { beef: 1400, chicken: 1200 }
                },
                {
                  n: 'alfredo',
                  bg: 'alfredo',
                  desc: ['Fuel for your carb-uretor'],
                  options: { chicken: 1800, shrimp: 2000 }
                },
                // {
                //   n: '360',
                //   bg: '360',
                //   desc: ['House Tuna made fresh daily, hand rolled in our soft flour tortilla shell or Roti skin'],
                //   price: 650
                // },
                {
                  n: 'fly high',
                  bg: 'fly_high',
                  desc: ['6pc Fried wings dipped in your choice of spicy bbq, lemon pepper, honey garlic or hot sauce'],
                  fries: true,
                  sauces: ['spicy bbq', 'lemon pepper', 'honey garlic', 'hot sauce'],
                  price: 1000
                },
                {
                  n: 'da hut',
                  bg: 'da_hut',
                  desc: ['It is always the right time for waffles'],
                  price: 1000
                },
                {
                  n: 'crush dem',
                  bg: 'crush_dem',
                  desc: ['3 pc Fried Chicken tenders'],
                  fries: true,
                  price: 1000
                },
                {
                  n: 'broken eggs',
                  bg: 'broken_eggs',
                  desc: ['An omelette to power your adventures'],
                  options: { chicken: 1000, shrimp: 1100 }
                },
                {
                  n: 'personal pizza pie',
                  bg: 'pizza_pie',
                  desc: ['Round cheesy perfection'],
                  options: { pepperoni: 1400, hawaiian: 1000, 'bbq chicken': 1200 }
                },
                {
                  n: 'shrimp',
                  bg: 'shrimp',
                  desc: ['Garlic or coconut curry', 'nitros for your taste buds'],
                  sauces: ['garlic', 'coconut'],
                  price: 1800
                },
                {
                  n: 'zip line platter',
                  bg: 'platter',
                  desc: ['Crab cakes, shrimp, egg rolls, fish sticks', 'yum yum'],
                  price: 2000
                }
              ],
              beverages: [
                {
                  n: 'ginger_beer',
                  price: 200
                },
                {
                  n: 'ting',
                  price: 200
                },
                {
                  n: 'cran_wata',
                  price: 200
                },
                {
                  n: 'pepsi',
                  price: 200
                },
                {
                  n: 'coke',
                  price: 200
                },
                {
                  n: 'tropical_rhythms',
                  price: 350
                }
              ],
              flasks: [
                {
                  n: 'white_rum',
                  price: 1000
                },
                {
                  n: 'kingston_62',
                  price: 1000
                },
                {
                  n: 'campari',
                  price: 1000
                },
                {
                  n: 'coco_mania',
                  price: 1000
                },
                {
                  n: 'smirnoff',
                  price: 1000
                },
                {
                  n: 'smirnoff_apple',
                  price: 1000
                },
                {
                  n: 'appleton_vx',
                  price: 1500
                }
              ],
              beers: [
                {
                  n: 'red_stripe',
                  price: 300
                },
                {
                  n: 'heineken',
                  price: 300
                },
                {
                  n: 'guinness',
                  price: 300
                },
                {
                  n: 'dragon',
                  price: 300
                },
                {
                  n: 'red_stripe_melon',
                  price: 300
                },
                {
                  n: 'red_stripe_sorrel',
                  price: 300
                },
                {
                  n: 'red_stripe_lemon',
                  price: 300
                },
                {
                  n: 'red_stripe_light',
                  price: 300
                }
              ],
              sides: [
                {
                  n: 'fries',
                  price: 200
                },
                {
                  n: 'cheese_fries',
                  price: 300
                },
                {
                  n: 'happy_fries',
                  price: 400
                },
                {
                  n: 'ripe_plantains',
                  price: 300
                },
                {
                  n: 'green_plantains',
                  price: 300
                },
                {
                  n: 'corn',
                  price: 350
                },
                {
                  n: 'mozzarella_sticks',
                  price: 650
                },
                {
                  n: 'mashed_potatoes',
                  price: 300
                }

                /*,
                {
                  n: 'roti',
                  price: 100
                }*/
              ],
              kids: [
                {
                  n: 'grilled_cheese',
                  price: 550
                },
                {
                  n: 'toasted_pbj',
                  price: 550
                },
                {
                  n: 'hot_dog',
                  price: 550
                },
                {
                  n: 'waffle',
                  price: 550
                }
              ],
              info: {
                title: 'pit stop',
                days: ['tue-sun'],
                hours: ['11-10'],
                whatsApp: '14048222357',
                phone: [{ areaCode: '876', digits: '535.6000' }],
                address: 'a4 long bay portland',
                closed: [1]
              }
            },
            covers: {
              info: {
                title: 'covers',
                days: [''],
                hours: [''],
                whatsApp: '18763623590',
                phone: [{ areaCode: '876', digits: '362.3590' }],
                address: 'a4 long bay portland',
                closed: [1]
              }
            },
            roots21: {
              items: [
                {
                  n: '1/4 chicken',
                  bg: '1_4_chicken',
                  desc: ['your choice of preparation'],
                  sauces: ['barbifry', 'curry', 'fried', 'jerk', 'sweet \'n sour'],
                  price: 700
                },
                {
                  n: 'roots grilled ribs',
                  bg: 'beef_ribs',
                  desc: ['3 beef ribs', 'served with mashed sweet potato'],
                  price: 3500
                },
                {
                  n: 'garlic alfredo',
                  bg: 'alfredo',
                  desc: ['choice of chicken, vegatable, or shrimp'],
                  options: { chicken: 1800, vegatable: 1800, shrimp: 2500 }
                },
                {
                  n: 'house salad',
                  bg: 'salad',
                  desc: ['healthy, hearty, happy'],
                  options: { plain: 900, chicken: 1300, fish: 1500 }
                },
                {
                  n: 'beef burger',
                  bg: 'burger',
                  desc: ['ground beef, fried egg, turkey, bacon, lettuce, tomato, cheese'],
                  fries: true,
                  price: 1200
                },
                {
                  n: 'fish burger',
                  bg: 'fish_burger',
                  desc: ['fish filet, lettuce, tomato, red onion'],
                  fries: true,
                  price: 1200
                },
                {
                  n: 'vegan burger',
                  bg: 'vegan_burger',
                  desc: ['vegan patty, lettuce & tomato'],
                  fries: true,
                  price: 1200
                },
                {
                  n: 'oxtail',
                  bg: 'oxtail',
                  desc: ['served with basmati rice & peas'],
                  price: 1500
                },
                {
                  n: 'curry goat',
                  bg: 'curry_goat',
                  desc: ['served with basmati rice & peas'],
                  price: 1500
                },
                {
                  n: 'fish & chips',
                  bg: 'fish_chips',
                  desc: ['fried marlin strips served with fries and esco sauce'],
                  fries: true,
                  price: 1500
                },
                {
                  n: 'seafood boil',
                  bg: 'seafood_boil',
                  desc: ['blue crab, shrimp, lobster, corn, & potato', '(serves 2)'],
                  price: 5000
                },
                {
                  n: 'fish',
                  bg: 'fried_fish',
                  desc: ['your choice of preparation'],
                  sauces: ['brown stew', 'coconut curry', 'curry', 'coconut esco', 'escovitch', 'fried', 'roast', 'steamed'],
                  price: 1800
                },
                {
                  n: 'whole lobster',
                  bg: 'whole_lobster',
                  desc: ['your choice of preparation'],
                  sauces: ['curry', 'fried', 'garlic', 'grilled'],
                  price: 2200
                },
                {
                  n: 'lobster tail',
                  bg: 'lobster_tail',
                  desc: ['your choice of preparation'],
                  sauces: ['curry', 'fried', 'garlic', 'grilled'],
                  price: 2500
                },
                {
                  n: 'shrimp',
                  bg: 'shrimp',
                  desc: ['your choice of preparation'],
                  sauces: ['coconut curry', 'curry', 'fried', 'garlic', 'grilled'],
                  price: 2000
                },
                {
                  n: 'bowl of soup',
                  bg: 'soup',
                  desc: ['belly bliss'],
                  options: { beef: 600, chicken: 600, seafood: 800 }
                },
                {
                  n: 'cup of soup',
                  bg: 'soup',
                  desc: ['a perfect portion'],
                  options: { beef: 250, chicken: 250, seafood: 400 }
                },
                {
                  n: 'roots wraps',
                  bg: 'wraps',
                  desc: ['two per order'],
                  options: { chicken: 600, fish: 800, 'all di veggie': 600 }
                },
                {
                  n: 'irish choka',
                  bg: 'irish_choka',
                  desc: ['served with roti'],
                  sauces: ['with saltfish', 'without'],
                  price: 700
                },
                {
                  n: 'pumpkin talkari',
                  bg: 'pumpkin_talkari',
                  desc: ['served with roti'],
                  sauces: ['with saltfish', 'without'],
                  price: 700
                }
              ],
              beverages: [
                {
                  n: 'ginger_beer',
                  price: 200
                },
                {
                  n: 'ting',
                  price: 200
                },
                {
                  n: 'cran_wata',
                  price: 200
                },
                {
                  n: 'pepsi',
                  price: 200
                },
                {
                  n: 'coke',
                  price: 200
                },
                {
                  n: 'tropical_rhythms',
                  price: 350
                }
              ],
              flasks: [
                {
                  n: 'white_rum',
                  price: 1000
                },
                {
                  n: 'kingston_62',
                  price: 1000
                },
                {
                  n: 'campari',
                  price: 1000
                },
                {
                  n: 'coco_mania',
                  price: 1000
                },
                {
                  n: 'smirnoff',
                  price: 1000
                },
                {
                  n: 'smirnoff_apple',
                  price: 1000
                },
                {
                  n: 'appleton_vx',
                  price: 1500
                }
              ],
              beers: [
                {
                  n: 'red_stripe',
                  price: 300
                },
                {
                  n: 'heineken',
                  price: 300
                },
                {
                  n: 'guinness',
                  price: 300
                },
                {
                  n: 'dragon',
                  price: 300
                },
                {
                  n: 'red_stripe_melon',
                  price: 300
                },
                {
                  n: 'red_stripe_sorrel',
                  price: 300
                },
                {
                  n: 'red_stripe_lemon',
                  price: 300
                },
                {
                  n: 'red_stripe_light',
                  price: 300
                }
              ],
              sides: [
                {
                  n: 'saltfish_fritters',
                  price: 300,
                  noBg
                },
                {
                  n: 'plantain',
                  price: 300,
                  noBg
                },
                {
                  n: 'sweet_mashed',
                  price: 300,
                  noBg
                },
                {
                  n: 'sweet_wedges',
                  price: 300,
                  noBg
                },
                {
                  n: 'sweet_chips',
                  price: 300,
                  noBg
                },
                {
                  n: 'rice_--_peas',
                  price: 300,
                  noBg
                },
                {
                  n: 'mashed_potatoes',
                  price: 300,
                  noBg
                },
                {
                  n: 'breadfruit',
                  price: 300,
                  noBg
                },
                {
                  n: 'seasoned_fries',
                  price: 300,
                  noBg
                },
                {
                  n: 'roti',
                  price: 200,
                  noBg
                },
                {
                  n: 'festival',
                  price: 200,
                  noBg
                },
                {
                  n: 'bammy',
                  price: 200,
                  noBg
                },
                {
                  n: 'steamed_veg',
                  price: 400,
                  noBg
                },
                {
                  n: 'coffee_rum_cake',
                  price: 600,
                  noBg
                },
                {
                  n: 'blue_mt_coffee',
                  price: 400,
                  noBg
                }
              ],
              info: {
                title: 'roots 21',
                days: [''],
                hours: [''],
                whatsApp: '18762887657',
                phone: [{ areaCode: '876', digits: '398.7722' }],
                address: 'a4 port antonio portland',
                closed: [1]
              }
            },
            'cliff-hanger': {
              NOTitems: [
                {
                  n: 'House salad',
                  desc: ['shredded cabbage, carrots, cucumber, tomatoes'],
                  price: 8
                },
                {
                  n: 'Tropical salad',
                  desc: ['seasonal fruits'],
                  price: 9
                },
                {
                  n: 'Conch salad',
                  desc: ['Conch, cucumber, pineapple, apple, tomatoes, honey, spicy jerk sauce, rice vinegar', 'seasonal'],
                  price: 11
                },
                {
                  n: 'Lobster salad',
                  desc: ['Lobster, cabbage, carrots, lettuce, pineapple, honey, spicy jerk sauce, rice vinegar'],
                  price: 14
                },
                {
                  n: 'Cliffhanger platter',
                  desc: ['Wings, fish, cod-fish fritters (cod fish shredded in a light batter with herbs and spices'],
                  price: 28
                },
                {
                  n: 'Curried Goat',
                  desc: ['Authentic Jamaican style cooked to perfection finger-licking goodness'],
                  side: true,
                  price: 16
                },
                {
                  n: 'Ox Tail',
                  desc: ['Slow cooked and simmered patiently to a fault complimented with bits of carrots, Irish potatoes, and sweet peppers'],
                  side: true,
                  price: 24
                },
                {
                  n: 'Chicken',
                  desc: ['choice of preparation'],
                  sauces: ['bar-b-fried', 'bar-b-q', 'curried', 'fried', 'sweet n sour', 'escovietch', 'grilled', 'wings'],
                  side: true,
                  price: 15
                },
                {
                  n: 'Pasta',
                  desc: ['served with a creamy sauce'],
                  options: { plain: 12, chicken: 23, shrimp: 31, lobster: 37, conch: 26 },
                  side: true
                },
                {
                  n: 'Lion fish',
                  desc: ['Award winning signature dish', 'choice of preparation'],
                  sauces: ['drunken', 'coconut curry', 'coconut', 'brown stew'],
                  side: true,
                  price: 28
                },
                {
                  n: 'Red snapper',
                  desc: ['choice of preparation'],
                  sauces: ['coconut curry', 'brown stew', 'escovietch', 'steamed', 'deep fried', 'grilled'],
                  side: true,
                  price: 28
                },
                {
                  n: 'Shrimp',
                  desc: ['choice of preparation'],
                  sauces: ['curry', 'scampi', 'honey curled', 'coconut'],
                  side: true,
                  price: 31
                },
                {
                  n: 'Lobster',
                  desc: ['choice of preparation'],
                  sauces: ['grilled', 'sweet shell', 'scampi', 'coconut curry', 'curry', 'coconut'],
                  side: true,
                  price: 37
                },
                {
                  n: 'Vegatable stir fry',
                  desc: ['Cabbage, carrots, bok choi, pumpkin, Irish potatoes, sweet peppers', 'vegan'],
                  sauces: ['coconut', 'coconut curry'],
                  side: true,
                  price: 17
                },
                {
                  n: 'Ital stew',
                  desc: ['Red kidney beans, cabbage, carrots, sweet peppers, Irish potatoes, and pumkins cooked in coconut sauce', 'vegan'],
                  side: true,
                  price: 17
                },
                {
                  n: 'Thai curry',
                  desc: ['Cabbage, carrots, bok choi, noodles, Irish potatoes, and sweet peppers'],
                  side: true,
                  options: { chicken: 23, shrimp: 31, lobster: 37, conch: 26 }
                },
                {
                  n: 'Thai coconut curry',
                  desc: ['Cabbage, carrots, bok choi, noodles, Irish potatoes, and sweet peppers'],
                  side: true,
                  options: { chicken: 23, shrimp: 31, lobster: 37, conch: 26 }
                }
              ],
              NOTbeverages: [
                {
                  n: 'ginger_beer',
                  price: 2
                },
                {
                  n: 'ting',
                  price: 2
                },
                // {
                //   n: 'cran_wata',
                //   price: 2
                // },
                {
                  n: 'pepsi',
                  price: 2
                }//,
                // {
                //   n: 'coke',
                //   price: 2
                // },
                // {
                //   n: 'tropical_rhythms',
                //   price: 4
                // }
              ],
              NOTflasks: [
                {
                  n: 'white_rum',
                  price: 11
                },
                {
                  n: 'kingston_62',
                  price: 11
                },
                {
                  n: 'campari',
                  price: 11
                },
                {
                  n: 'coco_mania',
                  price: 11
                },
                {
                  n: 'smirnoff',
                  price: 11
                },
                {
                  n: 'smirnoff_apple',
                  price: 11
                },
                {
                  n: 'appleton_vx',
                  price: 17
                }
              ],
              NOTbeers: [
                {
                  n: 'red_stripe',
                  price: 3
                },
                {
                  n: 'heineken',
                  price: 3
                },
                {
                  n: 'guinness',
                  price: 3
                },
                {
                  n: 'dragon',
                  price: 3
                },
                // {
                //   n: 'red_stripe_melon',
                //   price: 3
                // },
                {
                  n: 'red_stripe_sorrel',
                  price: 3
                }//,
                // {
                //   n: 'red_stripe_lemon',
                //   price: 3
                // },
                // {
                //   n: 'red_stripe_light',
                //   price: 3
                // }
              ],
              NOTsides: [
                {
                  n: 'white_rice',
                  noBg: true,
                  price: 2
                },
                {
                  n: 'rice_and_red_beans',
                  noBg: true,
                  price: 2
                },
                {
                  n: 'bammy',
                  noBg: true,
                  price: 2
                },
                {
                  n: 'mashed_potatoes',
                  noBg: true,
                  price: 2
                },
                {
                  n: 'steamed_veg',
                  noBg: true,
                  price: 2
                },
                {
                  n: 'fries',
                  noBg: true,
                  price: 2
                },
                {
                  n: 'potato_wedges',
                  noBg: true,
                  price: 5
                },
                {
                  n: 'green_plantain',
                  noBg: true,
                  price: 2
                },
                {
                  n: 'grill_pineapple',
                  noBg: true,
                  price: 14
                },
                {
                  n: 'banana flambé',
                  noBg: true,
                  price: 8
                },
                {
                  n: 'melon delight',
                  noBg: true,
                  price: 8
                }
              ],
              info: {
                title: 'cliff hanger',
                days: ['7 days'],
                hours: ['8-7'],
                whatsApp: '18768601395',
                phone: [{ areaCode: '876', digits: '860.1395' }],
                address: 'a4 long bay  portland',
                closed: []
              }
            },
            'secrets-chill-out': {
              items: [
                {
                  n: 'alfredo',
                  bg: 'alfredo',
                  desc: ['creamy delicious', 'choice of preparation'],
                  sauces: ['chicken', 'shrimp'],
                  price: 2000
                },
                {
                  n: 'shrimp',
                  bg: 'garlic_shrimp',
                  desc: ['choice of preparation'],
                  options: { 'garlic': 2500, 'coconut curry': 2800, 'teriyaki': 2800 }
                },
                {
                  n: 'peppered shrimp',
                  bg: 'peppered_shrimp',
                  desc: [''],
                  price: 1500
                },
                {
                  n: 'lamb',
                  bg: 'lamb',
                  desc: ['Pan seared lamb chops with a side of sauteed vegatables or garden salad'],
                  sauces: ['vegatables', 'salad'],
                  price: 3850
                },
                {
                  n: 'grilled chicken',
                  bg: 'grilled_chicken',
                  desc: ['served with a lite spicy red stripe sauce'],
                  price: 1200
                },
                {
                  n: 'lobster',
                  bg: 'lobster',
                  desc: ['choice of preparation, done to order'],
                  sauces: ['grilled', 'garlic', 'coconut curry', 'fried', 'poached'],
                  price: 5000
                },
                {
                  n: 'sea platter',
                  bg: 'seafood_platter',
                  desc: ['lobster, shrimp, calamari, crab, sweet corn, and potatoes', 'garlic redstrice sauce or coconut curry'],
                  sauces: ['red stripe', 'coconut curry'],
                  price: 5000
                },
                {
                  n: 'seafood boil',
                  bg: 'seafood_boil',
                  desc: [''],
                  options: { small: 3500, medium: 4500, large: 5000 }
                },
                {
                  n: 'curry',
                  bg: 'curry_conch',
                  desc: ['Coconut curry', 'conch or vegetarian bbq meatballs'],
                  options: { conch: 1500, vegetarian: 1500 }
                },
                {
                  n: 'curry crayfish',
                  bg: 'curry_crayfish',
                  desc: [''],
                  price: 3500
                },
                {
                  n: 'grilled salmon',
                  bg: 'grilled_salmon',
                  desc: ['honey glazed'],
                  price: 2500
                },
                {
                  n: 'braised oxtail',
                  bg: '',
                  desc: [''],
                  price: 2000
                },
                {
                  n: 'curry goat',
                  bg: 'curry_goat',
                  desc: [''],
                  price: 2000
                },
                {
                  n: 'calamari',
                  bg: 'calamari',
                  desc: ['Squid rings coated with breadcrumbs and fried', 'served with tartar sauce'],
                  price: 1200
                },
                {
                  n: 'licky di lips',
                  bg: 'licky_di_lips',
                  desc: ['mild wings', 'choice of sauce'],
                  sauces: ['blue cheese', 'sweet n sour'],
                  price: 1000
                },
                {
                  n: 'spicy licky di lips',
                  bg: 'licky_di_lips',
                  desc: ['spicy wings', 'choice of sauce'],
                  sauces: ['blue cheese', 'sweet n sour'],
                  price: 1000
                },
                {
                  n: 'crispy tempura shrimp',
                  bg: 'tempura_shrimp',
                  desc: ['served with a sweet chili citrus sauce'],
                  price: 1400
                },
                {
                  n: 'quesadilla',
                  bg: 'quesadilla',
                  desc: ['choice of preparation'],
                  options: { shrimp: 1800, lobster: 1800, chicken: 1200, vegetarian: 1000 },
                  fries: true
                }
              ],
              beverages: [
                {
                  n: 'ginger_beer',
                  price: 200
                },
                {
                  n: 'ting',
                  price: 200
                },
                {
                  n: 'pepsi',
                  price: 200
                },
                {
                  n: 'coke',
                  price: 200
                },
                {
                  n: 'tropical_rhythms',
                  price: 450
                }
              ],
              flasks: [
                {
                  n: 'white_rum',
                  price: 1000
                },
                {
                  n: 'kingston_62',
                  price: 1000
                },
                {
                  n: 'campari',
                  price: 1000
                },
                {
                  n: 'coco_mania',
                  price: 1000
                },
                {
                  n: 'smirnoff',
                  price: 1000
                },
                {
                  n: 'smirnoff_apple',
                  price: 1000
                },
                {
                  n: 'appleton_vx',
                  price: 1500
                }
              ],
              beers: [
                {
                  n: 'red_stripe',
                  price: 300
                },
                {
                  n: 'heineken',
                  price: 350
                },
                {
                  n: 'guinness',
                  price: 350
                },
                {
                  n: 'dragon',
                  price: 350
                },
                {
                  n: 'red_stripe_melon',
                  price: 350
                },
                {
                  n: 'red_stripe_sorrel',
                  price: 350
                },
                {
                  n: 'red_stripe_lemon',
                  price: 350
                },
                {
                  n: 'red_stripe_light',
                  price: 350
                }
              ],
              sides: [
                {
                  n: 'potato wedges',
                  noBg: true,
                  price: 400
                },
                {
                  n: 'steak fries',
                  noBg: true,
                  price: 400
                },
                {
                  n: 'fries',
                  noBg: true,
                  price: 400
                },
                {
                  n: 'spiral fries',
                  noBg: true,
                  price: 400
                },
                {
                  n: 'bammy',
                  noBg: true,
                  price: 300
                },
                {
                  n: 'plaintains',
                  noBg: true,
                  price: 300
                },
                {
                  n: 'festival',
                  noBg: true,
                  price: 300
                },
                {
                  n: 'sauteed potatoes',
                  noBg: true,
                  price: 400
                },
                {
                  n: 'mash potatoes',
                  noBg: true,
                  price: 400
                },
                {
                  n: 'rice n peas',
                  noBg: true,
                  price: 300
                }
              ],
              info: {
                title: 'secrets chill out',
                days: ['tue-sun'],
                hours: ['10-7'],
                whatsApp: '18762193003',
                phone: [{ areaCode: '876', digits: '219.3003' }],
                address: 'a4 long bay  portland',
                closed: [1]
              }
            },
            'coastline-bliss': {
              items: [
                {
                  n: 'Soup of the Day',
                  desc: ['Experience one of our mouthwatering broths to start off your dining experience.'],
                  price: 400
                },
                {
                  n: 'Stamp n\' Go',
                  desc: ['Flavor packed flitter made with cured saltfish, deep friend in a seasoned batter of flour, tomatoes, scallions, onions and spices.'],
                  price: 400
                },
                {
                  n: 'Crack Conch',
                  desc: ['Delicate conch flesh dipped in seasoned batter and fried to a crisp.'],
                  price: 1000
                },
                {
                  n: 'Crack Lobster',
                  desc: ['Succulent lobster flesh dipped in a seasoned batter and fried to a crisp.'],
                  price: 1000
                },
                {
                  n: 'Buffalo Wings',
                  desc: ['Sticky, spiced, unbreaded chicken wing drumette deep fried then coated in a rich vinegar based, cayenne pepper hot sauce with melted butter.'],
                  price: 1000
                },
                {
                  n: 'BBQ Chicken Wings',
                  desc: ['Baked chicken wings caramelized in a thick barbeque sauce.'],
                  price: 1000
                },
                {
                  n: 'Hot and Spicy Wings',
                  desc: ['Chicken Wings baked to perfection with a seasoned coating, then tossed with a homemade hot and spicy sauce.'],
                  price: 1000
                },
                {
                  n: 'Conch Fritters',
                  desc: ['Small bites of local conch meat fried in a savoury batter with a combination of complementary seasons.'],
                  price: 1000
                },
                {
                  n: 'Escovitched Fish',
                  desc: ['A traditional, Jamaican, deep fried fish that is marinated in peppery vinegar dressing, paired with carrots, onions and bell peppers.'],
                  price: 1800
                },
                {
                  n: 'Steamed Fish',
                  desc: ['Steamed fish cooked at the bone, never dry,, seasoned with local herbs and spices and served in a smooth vegetable broth.'],
                  price: 1800
                },
                {
                  n: 'Roast Fish',
                  desc: ['Chunky fish lathered in butter then seasoned with local herbs and spices and roasted to absolute perfection.'],
                  price: 1800
                },
                {
                  n: 'Stewed Fish',
                  desc: ['Fried fish that is stewed on a low heat in a sweet-savoury sauce that is flavoured with Jamaican herbs and spices.'],
                  price: 1800
                },
                {
                  n: 'Fried Lobster',
                  desc: ['Lobster tail dusted in a seasoned dry mixture, deep fried for a crisp juicy finish.'],
                  price: 3000
                },
                {
                  n: 'Garlic Butter Lobster',
                  desc: ['Lobster flesh boiled in seasoned broth then coated with a sweet garlic and butter paste, drizzled with white wine and baked until tender.'],
                  price: 3000
                },
                {
                  n: 'Lobster Thermidor',
                  desc: ['Lobster shells are stuffed with cooked lobster in a creamy white wine sauce, then topped with parmesan cheese and broiled until golden.'],
                  price: 3000
                },
                {
                  n: 'Grilled Lobster',
                  desc: ['Grilled lobster flesh that is smeared with sauce of whisked butter, lemon juice zest, chives, parsley, garlic and salt.'],
                  price: 3000
                },
                {
                  n: 'Coconut Shrimp',
                  desc: ['Crispy shrimp rolled in a coconut beer batter before frying, served with an orange marmalade mustard and horse radish sauce.'],
                  price: 2000
                },
                {
                  n: 'Garlic Shrimp',
                  desc: ['Garlic infused shrimp sautéed in melted butter and olive oil and topped with pan sauce.'],
                  price: 2000
                },
                {
                  n: 'Curry Shrimp',
                  desc: ['Shrimp sautéed in onion and curry batter until tender, cooked into a tender broth of flour, sugar, ginger and milk.'],
                  price: 2000
                },
                {
                  n: 'Shrimp Kebab',
                  desc: ['Juicy shrimp marinated in cumin, Italian seasoning, lemon, garlic, pepper flakes, and fresh herbs, then threaded along with vibrant flavored vegetables.'],
                  price: 2000
                },
                {
                  n: 'Sizzling Fajita (Shrimp)',
                  desc: ['Made with grilled marinated shrimp and served on a bed of grilled onions and colorful bell peppers. The shrimp is marinated in red enchilada sauce, taco seasoning, garlic, cilantro, fresh lemon juice, and grilled.'],
                  price: 2000
                },
                {
                  n: 'Grilled Conch',
                  desc: ['Tender seasoned conch marinated in honey rum sauce and expertly grilled.'],
                  price: 2500
                },
                {
                  n: 'Curry Conch',
                  desc: ['Fresh conch curried yaad style with local herbs and spices.'],
                  price: 2500
                },
                {
                  n: 'Garlic Conch',
                  desc: ['Garlic infused conch sautéed in melted butter and olive oil and topped with pan sauce.'],
                  price: 2500
                },
                {
                  n: 'Stew Pork',
                  desc: ['Pork tenderloins simmered in local herbs and spices.'],
                  price: 1300
                },
                {
                  n: 'Baby Back Ribs',
                  desc: ['Tender pork ribs marinated with barbecue sauce and baked to perfection.'],
                  price: 1600
                },
                {
                  n: 'Pork Chops',
                  desc: ['Pork loins seasoned with steak seasoning and cooked in melted butter on medium heat and then basked in a creamy seasoned mixture of milk.'],
                  price: 2000
                },
                {
                  n: 'Jerk Pork',
                  desc: ['Premium Jamaican Pork, coated in spices and slow cooked over grill with green Pimento wood and served with spicy homemade jerk sauce.'],
                  price: 1600
                },
                {
                  n: 'Steak',
                  desc: ['Steak is bathed in whisky, marinated and then butter-baste on a hot grill.'],
                  price: 2500
                },
                {
                  n: 'Stew Beef',
                  desc: ['Chunks of well-marbled beef are seared in olive oil and then gently braised with garlic and onions in a wine-based broth.'],
                  price: 1300
                },
                {
                  n: 'Pepper Steak',
                  desc: ['Tender and juicy slices of steak mixed with sweet peppers and onions in a silky and flavorful sauce.'],
                  price: 2500
                },
                {
                  n: 'Sizzling Fajita (Beef)',
                  desc: ['expertly seasoned skirt steak topped with onions and bell peppers, served sizzling hot with fresh tortillas, guacamole, sour cream, and salsa.'],
                  price: 1800
                },
                {
                  n: 'Chicken Alfredo',
                  desc: ['Lightly seasoned grilled chicken breast strips, tossed in a rich, creamy parmesan sauce over a bed of hot fettuccini pasta.'],
                  price: 1500
                },
                {
                  n: 'Shrimp Alfredo',
                  desc: ['Sautéed garlic shrimp is paired with fettuccine pasta in a creamy parmesan sauce.'],
                  price: 2000
                },
                {
                  n: 'Chicken and Shrimp',
                  desc: ['A mixture of grilled chicken breast strips and garlic shrimps in parmesan sauce coupled with fettuccine pasta.'],
                  price: 1800
                },
                {
                  n: 'Vegetable Pasta',
                  desc: ['A healthy, low calorie, vegetarian pasta dish completed with roasted vegetables.'],
                  price: 1000
                },
                {
                  n: 'Curry Crab',
                  desc: ['Fresh crab prepared in an impactful and spicy curry sauce seasoned with scotch bonnet or chili peppers then sautéed in coconut juice for completion.'],
                  price: 2500
                },
                {
                  n: 'Crab Claw Bordelaise',
                  desc: ['Meaty crab claws swimming in a buttery garlic and green onion sauce with a hit of tang from the dressing and lemons.'],
                  price: 2000
                },
                {
                  n: 'Club Sandwich',
                  desc: ['A delicious deli classic, layered with cheese, mayonnaise and fresh lettuce,  caressed by a warm bun.'],
                  price: 1200
                },
                {
                  n: 'Chicken Quesadilla',
                  desc: ['A warm tortilla, packed with juicy chicken, peppers, onions, garlic, and lots of melty cheese.'],
                  price: 1000
                },
                {
                  n: 'Jerk Chicken',
                  desc: ['Tender chicken quarters marinated in a spicy Jamaican jerk sauce and smoked.'],
                  price: 1300
                },
                {
                  n: 'Cordon Bleu',
                  desc: ['Breaded chicken breasts stuffed with cheddar cheese wrapped in chicken ham and deep fried.'],
                  price: 1600
                },
                {
                  n: 'Bake Chicken',
                  desc: ['Tender, juicy, sheet pan chicken, baked to perfection and coated in a rich honey barbecue sauce.'],
                  price: 1000
                },
                {
                  n: 'Teriyaki Chicken',
                  desc: ['Chicken is marinated in a flavourful mixture of soy sauce and alcohol then grilled to sweet, smoky perfection and dipped in savoury sweet and sour sauce that will leave your taste buds in a frenzy.'],
                  price: 1500
                },
                {
                  n: 'Parmesan Chicken',
                  desc: ['Delicious, crispy breadcrumb coating, smothered in a rich homemade sauce and melted mozzarella cheese.'],
                  price: 2000
                },
                {
                  n: 'Sizzling Fajita (Chicken)',
                  desc: ['Tender marinated juicy chicken is grilled then sliced into strips and served with onions, peppers and mushrooms atop tortilla.'],
                  price: 1500
                },
                {
                  n: 'Oxtail',
                  desc: ['This traditional Jamaican style oxtail and butter beans, stewed in rich seasoned sauce epitomizes the term \'soul food”.'],
                  price: 1600
                },
                {
                  n: 'Curry Goat',
                  desc: ['A traditional Jamaican dish in which goat meat is infused with the flavors of curry powder, chopped onion, escallion, garlic, ginger, thyme and scotch bonnet.'],
                  price: 1300
                },
                {
                  n: 'Salt fish Rundung',
                  desc: ['A traditional Jamaican dish sautéed with aromatic seasonings.'],
                  price: 1000
                },
                {
                  n: 'Salt Mackerel Rundung',
                  desc: ['A traditional Jamaican dish in which salted mackerel is cooked down in seasoned coconut milk until it is reduced to a oily custard sauce.'],
                  price: 1000
                },
                {
                  n: 'Cabbage & Salt fish',
                  desc: ['Flaked cod fish is sauteed with onion, garlic, and tomato, then steamed with seasoned cabbage.'],
                  price: 1000
                },
                {
                  n: 'Bacon Loaded Fries',
                  desc: ['Crisp french fries loaded with hot Italian sausage, jalapeños, red onions, green onions, tomatoes, sour cream and a creamy and delicious cheese.'],
                  price: 1200
                },
                {
                  n: 'Reggae Salad',
                  desc: ['Crisp lettuce, sweet corn, sweet peppers, tomatoes, red cabbage and carrots. Served with dressing on the side.'],
                  price: 1000
                },
                {
                  n: 'Chicken Salad',
                  desc: ['Piled high with fresh greens, grilled red bell peppers, tangy sweet pineapple, cucumbers, and avocado. Then it\'s topped with grilled jerk chicken marinated in a blend of island spices and smoky habanero peppers.'],
                  price: 1000
                },
                {
                  n: 'Island Salad',
                  desc: ['Shredded chicken sliced cherry tomatoes, cucumbers and a homemade vinagrette.'],
                  price: 1000
                },
                {
                  n: 'Caesar Salad',
                  desc: ['Crispy romaine lettuce and croutons dressed with lemon juice (or lime juice), olive oil, egg, Worcestershire sauce, anchovies, garlic, Dijon mustard, Parmesan cheese, and black pepper.'],
                  price: 1000
                },
                {
                  n: 'Rice and Peas',
                  desc: ['Jamaican rice and peas is a popular dish served at nearly every dinner table across the island. Featuring Red Kidney Beans and typically infused with Coconut Milk, this side option provides a rich depth of flavor that complements any meat dish entrée superbly.'],
                  price: 400
                },
                {
                  n: 'Mashed Potatoes',
                  desc: ['Cooked potatoes, with a small amount of milk added, that have been mashed to a smooth fluffy consistency.'],
                  price: 400
                },
                {
                  n: 'Steam Vegetables',
                  desc: ['Vegetables such as bell pepper, onion, thyme, scallion, tomato and carrot are seasoned and steamed together.'],
                  price: 400
                },
                {
                  n: 'Ripe Plantain',
                  desc: ['Ripe sweet plantains are fried until golden brown with a caramelized texture and sweet flavor that melts in your mouth.'],
                  price: 400
                },
                {
                  n: 'Fries',
                  desc: ['Deep-fried, very thin, salted slices of potato that are usually served at room temperature.'],
                  price: 400
                },
                {
                  n: 'Potato Wedges',
                  desc: ['Crispy oven baked potato wedges.'],
                  price: 400
                },
                {
                  n: 'Festival',
                  desc: ['A deliciously sweet side dish, crispy on the outside and soft & sweet on the inside and may feature a wide variety of spices and seasonings.'],
                  price: 400
                },
                {
                  n: 'Bammy',
                  desc: ['Grated cassava is shaped into a disc, and cooked in a skillet, soaked in coconut milk for a moist flatbread that is crisp on the outside.'],
                  price: 400
                },
                {
                  n: 'White Rice',
                  desc: ['An essential staple in any wholesome meal. White rice offers an absorbent canvas for the diversity of flavors that lay latent within our entrée selections.'],
                  price: 400
                },
                {
                  n: 'Green Plantain',
                  desc: ['A commonly used staple in Jamaican dishes, green plantains add another dimension of savory flavor to any main dish.'],
                  price: 400
                },
                {
                  n: 'Crackers',
                  desc: ['Crackers are popular for breakfast or as a snack. They are fat free and made with baking flour and water. A classic addition to any main dish featuring fish or other meats.'],
                  price: 400
                },
                {
                  n: 'Under Water',
                  desc: ['A treasure trove of flavor lay hidden within this feast. Prepare to EatNDream as we provide your dinner party with shore satisfaction.'],
                  price: 4000
                },
                {
                  n: 'Under Fire',
                  desc: ['A treasure trove of flavor lay hidden within this feast. Prepare to EatNDream as we provide your dinner party with shore satisfaction.'],
                  price: 4000
                },
                {
                  n: 'Surf N Turf',
                  desc: ['A treasure trove of flavor lay hidden within this feast. Prepare to EatNDream as we provide your dinner party with shore satisfaction.'],
                  price: 4000
                },
                {
                  n: 'Mukbang',
                  desc: ['A treasure trove of flavor lay hidden within this feast. Prepare to EatNDream as we provide your dinner party with shore satisfaction.'],
                  price: 4000
                },
                {
                  n: 'Sunday Bliss',
                  desc: ['A treasure trove of flavor lay hidden within this feast. Prepare to EatNDream as we provide your dinner party with shore satisfaction.'],
                  price: 15000
                },
                {
                  n: 'Oreo Cheese Cake',
                  desc: ['An amazingly decadent slice of heaven. This monument to cheesecake comes infused with cookies and cream and topped off with a wickedly seductive chocolate ganache.'],
                  price: 1000
                },
                {
                  n: 'Chocolate Sundae',
                  desc: ['A sublime ice-cream dessert garnished with a rich chocolate fudge sauce. A must have for any confectionary enthusiast.'],
                  price: 1000
                },
                {
                  n: 'Potato Pudding',
                  desc: ['Made using grated white sweet potatoes and coconut milk, Potato Pudding is a Jamaican favorite for anytime of the day.'],
                  price: 1000
                },
                {
                  n: 'Black Magic (Chocolate Cake)',
                  desc: ['A moist and magical buttermilk chocolate cake that will leave you  yearning for more.'],
                  price: 1000
                },
                {
                  n: 'Booza (Vanilla Ice Cream)',
                  desc: ['An incredibly creamy, dense and sweet vanilla treat.'],
                  price: 1000
                }/**/ /*,
                {
                  n: 'Coastline Pleaser',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'Leg Spreader',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'Portland Bliss',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'Een Ehh',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'Bumboclaat',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'Coastline Bully',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'MelliBoo',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'Seacrets',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'Dawg Head',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'WOW',
                  desc: ['Just one of countless selections to enjoy, courtesy of our master mixologist. Tailor your personal route to absolute euphoria on your way to Cloud 10 status.'],
                  price: 1000
                },
                {
                  n: 'Pina Colada',
                  desc: ['A tropical blend of rich coconut cream, white rum and tangy pineapple.'],
                  price: 700
                },
                {
                  n: 'Purple Rain',
                  desc: ['A zesty mix of Cherry Sourz, curaçao and lime topped with a twist of lemonade.'],
                  price: 700
                },
                {
                  n: 'Mudslide',
                  desc: ['Mudslide is a delicious dessert cocktail made with Bailey\'s Irish Cream, Kahlua, and vodka.'],
                  price: 700
                },
                {
                  n: 'Jamaican Delight',
                  desc: ['A deliciously delightful combination of dark rum, Tia Maria and sweet and sour mix into a glass with ice. Infused with pineapple juice.'],
                  price: 700
                },
                {
                  n: 'Dirty Banana',
                  desc: ['A rich blend of heavy cream, chocolate syrup, bananas and café liqueur. An absolutely sublime creamy concoction.'],
                  price: 700
                },
                {
                  n: 'Miami Vice',
                  desc: ['A sweet and refreshing blend complemented by the flavorful infusion of strawberries and coconut.'],
                  price: 700
                },
                {
                  n: 'Uncle Sam',
                  desc: ['A rich, high quality blend of liquers that elevate your dining experience to a whole new level.'],
                  price: 700
                },
                {
                  n: 'Daiquiri',
                  desc: ['A simple and sublime cocktail combining rum, lime and sugar.'],
                  price: 700
                },
                {
                  n: 'Blended Fruit Punch',
                  desc: ['A blend of grapefruit, pineapple and orange juice, and serve in a chilled glass and garnished with lime or pineapple slice.'],
                  price: 700
                },
                {
                  n: 'Classic Daiquiri',
                  desc: ['A rich, high quality blend of liquers that elevate your dining experience to a whole new level.'],
                  price: 700
                },
                {
                  n: 'Jamaican Smile',
                  desc: ['Whipped cream vodka, orange juice and a splash of cream served over crushed ice in a powdered sugar-rimmed glass.'],
                  price: 700
                },
                {
                  n: 'Apple Martini',
                  desc: ['Apple Martini drink is warm, enveloping and just enough refreshed by the sharp touch of triple sec.'],
                  price: 700
                },
                {
                  n: 'Cape Cod',
                  desc: ['The Cape Cod or Cape Codder is a type of cocktail consisting of vodka and cranberry juice.'],
                  price: 700
                },
                {
                  n: 'Whiskey Sour',
                  desc: ['The whiskey sour is a mixed drink containing whiskey (often bourbon), lemon juice, sugar, and optionally, a dash of egg white.'],
                  price: 700
                },
                {
                  n: 'Between the Sheets',
                  desc: ['The Between the Sheets is a cocktail consisting of white rum (or other light rum), cognac, triple sec, and lemon juice. When made with gin, instead of rum and cognac'],
                  price: 700
                },
                {
                  n: 'Lemonade',
                  desc: ['A blend of sweeteners and fresh lemon juice assure a refreshing experience, with a dash of childhood nostalgia.'],
                  price: 600
                },
                {
                  n: 'Amaretto Sour',
                  desc: ['A classic cocktail featuring amaretto and lemon juice. The Amaretto Sour is mostly sweet with enough sour to make it interesting.'],
                  price: 700
                },
                {
                  n: 'Appletini',
                  desc: ['Both sweet and sour with a subtle kick and the complexion of liquid kryptonite'],
                  price: 700
                },
                {
                  n: 'Sex on the Beach',
                  desc: ['The Sex on the Beach cocktail is known as much for its provocative name as its fruity, refreshing taste'],
                  price: 700
                },
                {
                  n: 'Shark Bite',
                  desc: ['Made of spiced rum, sour mix and blue curacao to make it appear as ocean water. Grenadine is used to create the appearance of blood in the water. A fun cocktail for the poolside'],
                  price: 700
                },
                {
                  n: 'Cersei',
                  desc: ['Cersei\'s sangria is sweetened with dragon fruit, blood orange and star fruit fit for a crown.'],
                  price: 700
                },
                {
                  n: 'Caipirinha',
                  desc: ['Contains fresh lime juice, sugar and cachaça'],
                  price: 700
                },
                {
                  n: 'Mai Tai',
                  desc: ['Mai Tai is a rum based cocktail with light and dark rums, almond syrup and pineapple juice'],
                  price: 700
                },
                {
                  n: 'Mad Rass',
                  desc: ['This cocktail is a tangy and refreshing, starring vodka, cranberry and orange juice'],
                  price: 700
                },
                {
                  n: 'Sea Breeze',
                  desc: ['A light, refreshing drink that is perfect for a day at the beach.'],
                  price: 700
                },
                {
                  n: 'Tequila Sunrise',
                  desc: ['The tequila sunrise is a cocktail made of tequila, orange juice, and grenadine syrup'],
                  price: 700
                },
                {
                  n: 'Black Russian',
                  desc: ['The Black Russian is a simple, two-part cocktail combining vodka and Kahlúa, a coffee liqueur that\'s made with rum, sugar and arabica coffee.'],
                  price: 700
                },
                {
                  n: 'Rum Punch',
                  desc: ['A sweet, fruity rum drink that gives you that tropical Caribbean feeling'],
                  price: 700
                },
                {
                  n: 'Caribbean Blue',
                  desc: ['A delicious mix of the dark, full flavored Coruba Original. Paired with a unmissable cola and coffee liqueur hit.'],
                  price: 900
                },
                {
                  n: 'Feeling Sexy',
                  desc: ['A rich, high quality blend of liquers that elevate your dining experience to a whole new level.'],
                  price: 900
                },
                {
                  n: 'Mojito',
                  desc: ['An incredibly refreshing fizzy lime drink mixed with rum and mint.'],
                  price: 900
                },
                {
                  n: 'Cosmopolitan',
                  desc: ['Made with vodka, triple sec, cranberry juice, and freshly squeezed or sweetened lime juice.'],
                  price: 900
                },
                {
                  n: 'Bloody Mary',
                  desc: ['The Bloody Mary is a cocktail consisting of a base of tomato juice and vodka. It is then seasoned with savory, usually a hot spicey ingredient, and lemon juice'],
                  price: 900
                },
                {
                  n: 'Jamaican Blue',
                  desc: ['A rich, high quality blend of liquers that elevate your dining experience to a whole new level.'],
                  price: 900
                },
                {
                  n: 'Long Island Ice Tea',
                  desc: ['The Long Island Iced Tea features cola, all the white liquors, Triple Sec and lemon juice'],
                  price: 900
                },
                {
                  n: 'Blue Lagoon',
                  desc: ['A refreshing, three-ingredient cocktail containing vodka, blue curaçao and lemonade.'],
                  price: 900
                },
                {
                  n: 'Manhattan',
                  desc: ['Manhattan is a classic cocktail of choice for whiskey-lovers. A delightful mix of rye or bourbon whiskey, sweet vermouth, and bitters'],
                  price: 900
                },
                {
                  n: 'Shake and Margarita',
                  desc: ['This offers a well-balanced combination of sweet, sour, salty, and bitter flavors'],
                  price: 900
                },
                {
                  n: 'Frozen Bob Marley',
                  desc: ['A rich, high quality blend of liquers that elevate your dining experience to a whole new level.'],
                  price: 900
                },
                {
                  n: 'French Kiss',
                  desc: ['French Kiss is a sparkling cocktail with fresh strawberry, pineapple and Champagne.'],
                  price: 900
                },
                {
                  n: 'Kiss on the Lips',
                  desc: ['Simply delicious, Kiss on the Lips Cocktail is a sweet martini with flavors of berry and pineapple.'],
                  price: 900
                },
                {
                  n: 'Tom Collins',
                  desc: ['An irresistible mix of gin, lemon juice, simple syrup and club soda, Tom Collins makes for a fizzy and refreshing drink.'],
                  price: 900
                },
                {
                  n: 'YOLO',
                  desc: ['Malibu coconut rum, peach liqueur and Crème de Banane, all shaken over ice, with fresh lime, sugar syrup and pineapple juice.'],
                  price: 1200
                },
                {
                  n: 'Slippery Nipple',
                  desc: ['This drink is made with equal parts butterscotch schnapps and Irish cream.'],
                  price: 800
                },
                {
                  n: 'Monkey Brains',
                  desc: ['Bound to deliver a huge range of flavors with every gulp, layered shots mix up vodka, Irish cream, lemon juice, and grenadine.'],
                  price: 800
                },
                {
                  n: 'Screaming Orgasm',
                  desc: ['A Screaming Orgasm layered shot is made by mixing Kahlua, amaretto liqueur and Bailey\'s irish cream. This is a shot best appreciated by the most hardened liquor connoisseurs.'],
                  price: 800
                },
                {
                  n: 'African Brains',
                  desc: ['A rich, high quality blend of liquers that elevate your dining experience to a whole new level.'],
                  price: 800
                },
                {
                  n: 'Viagra Liquid',
                  desc: ['A rich, high quality blend of liquers that elevate your dining experience to a whole new level.'],
                  price: 800
                },
                {
                  n: 'Bob Marley',
                  desc: ['This gorgeous, layered shot mixes up grenadine, Galliano, green creme de menthe, and 151 proof rum.'],
                  price: 800
                },
                {
                  n: 'Woo-Woo',
                  desc: ['Woo Woo is a fruity American cocktail which is considered a mix between Cosmopolitan and Hairy Navel. A rich blend of vodka, peach schnapps, and cranberry juice are also major players.'],
                  price: 500
                },
                {
                  n: 'Blue Balls',
                  desc: ['This concoction combines coconut rum, Blue Curaçao, Peach Schnapps, sweet & sour, and a splash of lemon lime soda for a drink that\'ll help you forget all your pent-up frustrations.'],
                  price: 500
                },
                {
                  n: 'Irish Flag',
                  desc: ['The Irish Flag shot is a layered shot cocktail featuring green creme de menthe, Baileys Irish Cream and Grand Marnier. This combination mimics the colors of the Irish flag – green, white and orange from bottom to top.'],
                  price: 500
                },
                {
                  n: 'Orgasm',
                  desc: ['This wild mix shot blends together a healthy proportion of Irish cream, grand marnier, and Triple Sec.'],
                  price: 500
                },
                {
                  n: 'Blow Job',
                  desc: ['The Blow Job is a guilty pleasure shot that combines amaretto, Irish cream and whipped cream'],
                  price: 500
                },
                {
                  n: 'Soursop Juice',
                  desc: ['Natural juices extracted from the sweet nectar of the Soursop fruit. A refreshing choice to complete your dining experience.'],
                  price: 600
                },
                {
                  n: 'Mango Juice',
                  desc: ['Natural juices extracted from the sweet nectar of the Mango fruit. A refreshing choice to complete your dining experience.'],
                  price: 600
                },
                {
                  n: 'Pineapple Juice',
                  desc: ['Natural juices extracted from the sweet nectar of the Pineapple fruit. A refreshing choice to complete your dining experience.'],
                  price: 600
                },
                {
                  n: 'Guava Juice',
                  desc: ['Natural juices extracted from the sweet nectar of the Guava fruit. A refreshing choice to complete your dining experience.'],
                  price: 600
                },
                {
                  n: 'June Plum Juice',
                  desc: ['Natural juices extracted from the sweet nectar of the June Plum fruit. A refreshing choice to complete your dining experience.'],
                  price: 600
                },
                {
                  n: 'Fruit Punch Juice',
                  desc: ['A delicious blend of natural juices extracted from local fruits renown for their richness and diversity of flavor.'],
                  price: 600
                },
                {
                  n: 'Coconut Water',
                  desc: ['Refreshing water extracted from locally grown coconuts, complemented by a rich, sweet, nutty taste.'],
                  price: 200
                },
                {
                  n: 'Sparkling Water',
                  desc: ['Fresh spring water retrieved and promptly carbonated to produce a refreshing, fizzy experience.'],
                  price: 300
                },
                {
                  n: 'Bottled Water',
                  desc: ['Fresh spring water bottled and prepared for your consumption.'],
                  price: 100
                },
                {
                  n: 'Soft Drinks',
                  desc: ['A wide selection of carbonated drinks that provide a sweet, fizzy experience.'],
                  price: 150
                }*/
              ],
              info: {
                title: 'coastline bliss',
                days: [''],
                hours: [''],
                whatsApp: '18763876948',
                phone: [{ areaCode: '876', digits: '871.1570' }],
                address: 'a4 port antonio portland',
                closed: []
              }
            },
            'margaritaville-mobay': {
              items: [
                {
                  n: 'Volcano Nachos',
                  desc: [
                    'Jalapenos, tomatoes, scallions, chili, and mounds of melted cheese heaped on a mountain of crispy tortilla chips. With cool guacamole and sour cream.'
                  ],
                  options: { loaded: 19.95, chicken: 21.95 }
                },
                {
                  n: 'Blackened Mini Shrimp Tacos',
                  desc: [
                    'Succulent shrimp coated with our blackened seasoning and chipotle-lime-cilantro aioli. Served in four mini flour tortillas with flavorful pico de gallo, sour cream, and cotillo cheese.'
                  ],
                  price: 13.75
                },
                {
                  n: 'Jerk BBQ Pork Tacos',
                  desc: [
                    'Tender braised pork, served with caramelized onions in four mini tortillas. Topped with pico de gallo, sour cream, and cotillo cheese.'
                  ],
                  price: 13.75
                },
                {
                  n: 'Crab Cakes',
                  desc: [
                    'Two loaded lump crab cakes lightly breaded and drizzled with Jimmy’s signature aioli sauce.'
                  ],
                  price: 13.75
                },
                {
                  n: 'Lava Lava Shrimp',
                  desc: [
                    'Plump, lightly-breaded shrimp, fried golden, and tossed with our savory Asian aioli sauce. Piled high on your plate and lightly sprinkled with black and white sesame seeds.'
                  ],
                  price: 13.75
                },
                {
                  n: 'Chicken Quesadillas',
                  desc: [
                    'Tortillas filled with mixed cheese, grilled chicken, roasted chili peppers, caramelized onions, and fresh cilantro. Served with sliced avocados and sour cream.'
                  ],
                  price: 13.75
                },
                {
                  n: 'Chicken Wings',
                  desc: [
                    'Seasoned chicken wings cooked until crispy and tossed in your choice of Buffalo or Jerk BBQ sauce. Served with creamy ranch or tangy bleu cheese dipping sauce.'
                  ],
                  price: 13.75
                },
                {
                  n: 'Island Calamari',
                  desc: [
                    'Tender, sweet calamari, lightly breaded, and fried golden brown. Served with Cajun aioli sauce for dipping.'
                  ],
                  price: 13.75
                },
                {
                  n: 'Appetizer Platter',
                  desc: [
                    'A delicious combination of Lava Lava Shrimp, Chicken Quesadillas, Chicken Wings, and a creamy spinach dip with crispy corn tortilla chips.'
                  ],
                  price: 27.95
                },
                {
                  n: 'Island Taco Salad',
                  desc: [
                    'Grilled fajita-style chicken with black beans, corn salsa, shredded cheese, guacamole, and sour cream on a bed of mixed greens. Served with crispy fried tortillas and a zesty chipotle ranch dressing.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Blackened Chicken Cobb Salad',
                  desc: [
                    'Chopped greens tossed with a tangy honey mustard dressing, blackened chicken, applewood smoked bacon, eggs, fresh avocados, tomatoes, and Gorgonzola cheese.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Caribbean Shrimp & Citrus Salad',
                  desc: [
                    'Crispy salad mixed with pecans, sweet peppers, carrots, avocado, sweet pineapple and oranges. Topped with citrus marinated shrimp and our signature paradise island vinaigrette.'
                  ],
                  price: 17.95
                },
                {
                  n: 'Wedge Salad',
                  desc: [
                    'A wedge of crisp, cool iceberg lettuce, diced tomatoes, red onions, chopped parsley, and Gorgonzola crumbles finished with creamy ranch dressing and cracked black pepper.'
                  ],
                  price: 12.95
                },
                {
                  n: 'Seafood Chowder',
                  desc: [
                    'A creamy, smoky chowder loaded with roasted corn and a generous amount of Caribbean seafood.'
                  ],
                  price: 7.95
                },
                {
                  n: 'Cheeseburger in Paradise',
                  desc: [
                    'So good Jimmy wrote a song about it – topped with velvety melted American cheese. With lettuce and tomato, Heinz 57, and French fried potatoes.'
                  ],
                  options: { single: 13.75, double: 15.95 }
                },
                {
                  n: 'B.O.B (Bacon, Onion, Bleu Cheese) Burger',
                  desc: [
                    'Layered with applewood smoked bacon, shoe string onion rings, bleu cheese dressing, lettuce, and tomatoes. This burger is topped with Gorgonzola cheese crumbles and served on a buttery brioche bun.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Jerk BBQ Cheeseburger',
                  desc: [
                    'Smothered in island-style jerk BBQ sauce, smoked bacon, rich cheddar cheese, lettuce, and tomatoes.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Fried Grouper Sandwich',
                  desc: [
                    'Lightly breaded, flaky grouper, golden fried to perfection, and topped with a tasty Cajun remoulade sauce. Served on a brioche bun with coleslaw and tomatoes.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Cuban Sandwich',
                  desc: [
                    'Slowly roasted premium pork, ham, Swiss cheese, sliced pickles, and tangy mustard pressed in a toasty Cuban loaf.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Honey Mustard Chicken Sandwich',
                  desc: [
                    'A marinated chicken breast topped with smoked applewood bacon, Swiss cheese, creamy mustard avocado, dressing. lettuce, and tomatoes. Served on a pretzel bun with honey mustard dressing.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Turkey Club Sandwich',
                  desc: [
                    'Layers of sliced turkey, stacked high on a toasted white bread with Swiss cheese, smoked bacon, lettuce, tomato, and mayonnaise.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Jerk Pulled Pork Sandwich',
                  desc: [
                    'Tender braised pork, topped with Caribbean coleslaw and shoe strong onion rings, served on a buttered brioche bun.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Blackened Chicken Sandwich',
                  desc: [
                    'A juicy, flavorful chicken breast coated with our blackened seasoning blend and served on a buttered brioche bun with lettuce, tomatoes, and Cajun remoulade sauce.'
                  ],
                  price: 14.95
                },
                {
                  n: 'Trouble in Paradise',
                  desc: [
                    'Take our Trouble in Paradise challenge. IF you finish our spectacular quadruple patty version of our Cheeseburger in Paradise, fries, pickle, and all, in less than 12 minutes, WE’LL pay for it!'
                  ],
                  price: 24.95
                },
                {
                  n: 'Combo Platter',
                  desc: [
                    'Half a rack of finger-licking baby back ribs, zesty blackened chicken, and succulent Coconut Shrimp – perched atop a bed of golden French Fries. Served with a tangy BBQ sauce for dipping.'
                  ],
                  price: 27.95
                },
                {
                  n: 'Margarita Pasta',
                  desc: [
                    'Tasty shrimp or chicken sautéed with shallots, cilantro, and fresh lime juice. Served with our delicious cream sauce over bowtie pasta and topped with cotija cheese.'
                  ],
                  options: { chicken: 18.95, shrimp: 23.95 }
                },
                {
                  n: 'Coconut Shrimp',
                  desc: [
                    'Plump, jumbo skewered shrimp, breaded with coconut batter and fried crispy and golden. Served with orange horseradish marmalade, whipped potatoes, and seasonal vegetables.'
                  ],
                  price: 19.95
                },
                {
                  n: 'Seafood Mac & Cheese',
                  desc: [
                    'Shrimp and lump crab meat with cavatappi corkscrew pasta in a boursin cream sauce, baked to homemade perfection.'
                  ],
                  price: 22.95
                },
                {
                  n: 'Grilled Mahi Mahi',
                  desc: [
                    'Grilled Mahi Mahi over island rice and vegetables served with a flavorful roasted corn, black bean, and cilantro salsa.'
                  ],
                  price: 19.95
                },
                {
                  n: 'Fish Tacos',
                  desc: [
                    'Crispy fried fish in our signature beer batter, wrapped in three grilled, flour tortillas. Layered with fresh avocado and lettuce, then drizzled with habanero cream sauce. Served with mango pico de gallo and island rice.'
                  ],
                  price: 19.95
                },
                {
                  n: 'Jerk BBQ Chicken',
                  desc: [
                    'Semi-boneless half chicken marinated in authentic jerk spices, roasted island style, and covered with a distinctive jerk BBQ sauce. Served with seasonal vegetables and island rice.'
                  ],
                  price: 18.95
                },
                {
                  n: 'Jimmy’s Jammin’ Jambalaya',
                  desc: [
                    'Jimmy’s Jambalaya recipe, loaded with plump shrimp, chicken, and Jamaican andouille sausage. Simmered in a spicy Cajun broth. Served with a side of fluffy white rice.'
                  ],
                  price: 19.95
                },
                {
                  n: 'Full Rack of Ribs',
                  desc: [
                    'A whole slab of “fall off the bone” baby back ribs with our tangy BBQ sauce perched atop a mound of golden French fries.'
                  ],
                  price: 27.95
                },
                {
                  n: 'Key Lime Pie',
                  desc: [
                    'A cool, creamy pie made with real Key limes – perfectly tart, sweet, and aromatic with a graham cracker crust and whipped cream.'
                  ],
                  price: 7.95
                },
                {
                  n: 'Flourless Chocolate Cake',
                  desc: [
                    'Decadent chocolate cake with a melted chocolate center. Served with whipped cream and tempting strawberry sauce.'
                  ],
                  price: 7.95
                },
                {
                  n: 'Bananarama',
                  desc: [
                    'A perfectly ripe banana rolled into a tortilla shell, fried crisp, and topped with a luscious chocolate sauce. Served with a scoop of vanilla bean ice cream on the side.'
                  ],
                  price: 7
                },
                {
                  n: 'Rum Cake',
                  desc: [
                    'A Caribbean rum flavored moist yellow cake served with raspberry sauce and whipped cream.'
                  ],
                  price: 7.95
                }
              ],
              info: {
                title: 'Margaritaville Mobay',
                days: ['7 days'],
                hours: ['10-10'],
                whatsApp: '',
                phone: [{ areaCode: '876', digits: '952.4777' }],
                address: 'Gloucester Ave, Montego Bay',
                closed: []
              }

            }
          };
        },
        orderAs64(state, { order }) {
          return `${ location.origin }?o=${ btoa(JSON.stringify(order)) }`;
        }
      }
    },
    props
  )
);
