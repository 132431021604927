import { createApp } from 'vue';
import App from './app.vue';
// import App from './components/print';
// import App from './components/print-roots';
//import App from './components/slot-machine';
// import App from './components/store-machine';
import store from './store';
import { lpFromUrl } from '@/helpers';
window.store = store;
window.g = store.getters;
const oTest = /[\?\;]o\=([^\;]+)/;
const pitstop = /876park|pit\-stop|pitstop/.test(location.href);
const roots21 = /roots21/.test(location.href);
const bliss = /bliss/.test(location.href);
const cliffhanger = /cliffhanger/.test(location.href);
const margaritavilleMobay = /margaritaville-mobay/.test(location.href);
const other = (location.search || '').replace(/\?/, '');
const lpInUrl = lpFromUrl(location.href);
// console.log('href', location.href);
if (location.search && oTest.test(location.search)) {
  const order = JSON.parse(atob((oTest.exec(location.search) || [])[1] || btoa('{}')));
  store.dispatch('order', order);
  store.dispatch('lp', order.lp);
  store.dispatch(
    'restaurant',
    (pitstop && 'pit-stop') || order.restaurant || 'pit-stop'
  );
} else if (lpInUrl) {
  console.log('lpInUrl', lpInUrl);
  loadFromLp(lpInUrl);
} else if (cliffhanger) {
  console.log('cliffhanger');
  store.dispatch('restaurant', 'cliff-hanger');
} else if (margaritavilleMobay) {
  console.log('margaritavilleMobay');
  store.dispatch('restaurant', 'margaritaville-mobay');
} else if (bliss) {
  console.log('bliss');
  store.dispatch('restaurant', 'coastline-bliss');
} else if (pitstop) {
  console.log('pit-stop');
  store.dispatch('restaurant', 'pit-stop');
} else if (roots21) {
  console.log('roots21');
  store.dispatch('restaurant', 'roots21');
} else if (other) {
  console.log('other', other);
  store.dispatch('restaurant', other);
} else {
  console.log('chooser');
  store.dispatch('restaurant', null);
}
createApp(App)
  .use(store)
  .mount('#app');
// store.subscribeAction(({ type, payload }) => {
//
// });
function loadFromLp(lp) {
  const { dispatch } = store;
  const stickers = {};
  [
    'LBR-677',
    'PVN-760',
    'CEK-109',
    'AAL-891',
    'TNH-420',
    'BMY-473',
    'CMR-381'
  ].forEach(sticker => stickers[sticker] = sticker => {
    setRestaurant('pit-stop');
    setStickerLocation('pit stop at bar');
    setSticker(sticker);
  });
  [
    'SFD-917'//next door
  ].forEach(sticker => {
    stickers[sticker] = () => {
      setRestaurant('secrets-chill-out');
      setStickerLocation('wild ponies');
      setSticker(sticker);
    };
  });
  [
    'VHA-256',
    'MBA-141',
    'IEQ-051',
    'GMF-919',
    'QSW-537',
    'YUW-377',
    'XQU-367',
    'LDH-133',
    'TRZ-667',
    'ADZ-922',
    'CGG-601',
    'TOB-278',
    'HSX-913',
    'KOU-328',
    'JWD-823',
    'BBL-374',
    'YLH-207',
    'EOD-221',
    'CKG-339',
    'ORB-645'
  ].forEach(sticker => {
    stickers[sticker] = () => {
      setRestaurant('secrets-chill-out');
      setStickerLocation('chill out at bar');
      setSticker(sticker);
    };
  });
  [
    'PYK-102'
  ].forEach(sticker => {
    stickers[sticker] = () => {
      setRestaurant('roots21');
      setStickerLocation('roots21 at bar');
      setSticker(sticker);
    };
  });
  const sticker = stickers[lp];
  if (sticker) {
    console.log('sticker', sticker);
    sticker(lp);
  }
  function setRestaurant(restaurant) {
    dispatch('restaurant', restaurant);
  }
  function setSticker(sticker) {
    console.log('setSticker', sticker);
    dispatch('fromSticker', sticker);
  }
  function setStickerLocation(stickerLocation) {
    dispatch('stickerLocation', stickerLocation);
  }
}
